import { Box, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { InputTooltip } from '../InputTooltip/InputTooltip';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { LoadingOption } from '../LookupInput/ApiLookupInputBase';

export type InputMultiLookupOption<T> = {
  /** The value that formik will use when setting values */
  value: T;
  /** The display label in the lookup */
  label: string;
  /** Optional sublabel */
  sublabel?: string;
};

type InputMultiLookupProps<FormikFieldType, OptionValueType> = {
  label?: string;
  name: string;
  options: InputMultiLookupOption<OptionValueType>[];

  /**
   * onMatchChange is a callback that is invoked when the selected options
   * change; if there are no matches, the callback is passed an empty array.
   *
   * Currently, onMatchChange is invoked immediately prior to touching and
   * setting the value of the Formik field, so within the callback, the previous
   * value is still accessible in Formik's state while the new value is passed
   * to the callback.
   */
  onMatchChange: (matches: InputMultiLookupOption<OptionValueType>[]) => void;

  /** Finds the options that correspond to what's in Formik state. */
  formValueMatcher: (
    formValue: FormikFieldType[],
    options: InputMultiLookupOption<OptionValueType>[],
  ) => InputMultiLookupOption<OptionValueType>[];

  /** @default empty */
  placeholder?: string;

  /** @default undefined */
  disabled?: boolean;
  /** Adding a tip will wrap the input component in a hoverable tooltip */
  tip?: string;
};

// Typing in the autocomplete box will match against the label or the sublabel
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: InputMultiLookupOption<any>) => `${option.label}${option.sublabel ?? ''}`,
});

/**
 * A formValueMatcher for the typical multi-lookup case for domain objects,
 * where Formik state contains an array of { id: string }, and the lookup
 * options' values are string IDs.
 */
export const ReferenceValueMatcher = (
  formValue: { id: string }[],
  options: InputMultiLookupOption<string>[],
): InputMultiLookupOption<string>[] => {
  const ids = formValue.map((v) => v.id);
  return options.filter((opt) => ids.includes(opt.value));
};

/**
 * A Slabstack formik-friendly multi-selection autocomplete.
 *
 * Must be used inside `<Formik><Form>{component}</Form></Formik>`.
 *
 * @example
 * <Formik {your_values_here} >
 * {(formikBag): JSX.Element => (
 *   <Form>
 *     <InputMultiLookup
 *       label='Companies'
 *       name='companies'
 *       options={companyOpts}
 *       onMatchChange={(companyIDs: string[]): void => {
 *         // ...
 *       }}
 *       formValueMatcher={ReferenceValueMatcher}
 *     />
 *   </Form>
 * </Formik>
 */
export const InputMultiLookup = <FormikFieldType, OptionValueType>({
  label,
  name,
  options,
  onMatchChange,
  formValueMatcher,
  placeholder,
  disabled,
  tip,
}: InputMultiLookupProps<FormikFieldType, OptionValueType>): JSX.Element => {
  const getOptFromValues = (v: FormikFieldType[]): InputMultiLookupOption<OptionValueType>[] =>
    formValueMatcher(v, options);

  const { getFieldMeta } = useFormikContext();
  const formikValue = getFieldMeta(name).value as FormikFieldType[];

  const [stateOpts, setStateOpts] = useState<InputMultiLookupOption<OptionValueType>[]>(
    getOptFromValues(formikValue),
  );

  // Ensure that stateOpts are in sync with the field value at all times.
  useEffect(() => {
    setStateOpts(getOptFromValues(formikValue));
  }, [formikValue, options]);

  return (
    <Field id={name} name={name}>
      {({
        field: { onBlur },
        form: { setFieldTouched },
        meta,
      }: FieldAttributes<any>): JSX.Element => {
        const loadingOptions = options.length === 1 && options[0] === LoadingOption;
        const hasError = meta.touched === true && meta.error !== undefined;
        const labelWithTip =
          label === undefined ? null : (
            <Box display='flex' gap='0.25rem' alignItems='center'>
              {label}
              {tip !== undefined ? <InputTooltip tip={tip} /> : null}
            </Box>
          );

        return (
          <Autocomplete
            multiple
            id={name}
            onBlur={onBlur}
            disabled={disabled}
            loading={loadingOptions}
            loadingText={LoadingOption.label}
            isOptionEqualToValue={(option, value): boolean =>
              value === undefined || option.value === value.value
            }
            value={stateOpts}
            onChange={(
              _: unknown,
              selectedOpts: InputMultiLookupOption<OptionValueType>[],
            ): void => {
              onMatchChange(selectedOpts);
              setStateOpts(selectedOpts);
              setFieldTouched(name);
            }}
            autoComplete={false}
            disablePortal
            fullWidth
            options={loadingOptions ? [] : options}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                name={name}
                error={hasError}
                helperText={hasError ? meta.error : ' '}
                placeholder={placeholder}
                label={labelWithTip}
                // Close outlined box + fix height if label is undefined
                sx={
                  label === undefined
                    ? {
                        fieldset: {
                          top: 0,
                        },
                        legend: {
                          display: 'none',
                        },
                      }
                    : {}
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingOptions ? (
                        <LoadingSpinner color='inherit' size={20} wrapInBox={false} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            filterOptions={filterOptions}
            renderOption={(params, option): JSX.Element => (
              <Box
                // 'params' handles all hover + click events + default css
                {...params}
                component='li'
                display='flex'
                flexDirection='column'
                alignItems='start !important'
                padding='0rem'
                whiteSpace='nowrap'
              >
                <Typography>{option.label}</Typography>
                <Typography variant='body3'>{option.sublabel}</Typography>
              </Box>
            )}
          />
        );
      }}
    </Field>
  );
};
