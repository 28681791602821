import { Box } from '@mui/material';
import _ from 'lodash';

import { DisplayField, SingleField } from '../../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Project } from '../../../generated-types/Project/Project';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { CurrencyString } from '../../../utils/Currency';

type ProjectDetailsColumnProps = {
  project: Project;
  onProjectDetailsClick: () => void;
  onAddressInformationClick: () => void;
  onDetailsClick: () => void;
  onNoteClick: () => void;
  onCustomFieldsClick: () => void;
};
export const ProjectDetailsColumn = ({
  project,
  onProjectDetailsClick,
  onAddressInformationClick,
  onDetailsClick,
  onNoteClick,
  onCustomFieldsClick,
}: ProjectDetailsColumnProps): JSX.Element => {
  const {
    isLoading,
    isError,
    data: customFieldDefinitions,
  } = useSlabQuery('GET custom field definitions', {});

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || customFieldDefinitions === undefined) {
    return <div>ERROR</div>;
  }

  const displayProject = {
    ...project,
    ...project.address,
    winnerName: project.winningCompanyName(),
    confidenceString: project.confidenceDisplay(),
    estStartDateString: project.estimatedStartDateDisplay(),
    estEndDateString: project.estimatedEndDateDisplay(),
    bidDateString: project.bidDateDisplay(),
    expirationDateString: project.expirationDateDisplay(),
    estimatedRevenueString: CurrencyString({ cur: project.revenue }),
    // Wrap the number in a String(parseFloat()) to remove trailing zeros
    estimatedMarginString: project.estimatedMarginDisplay(),
    pricePerYardDisplay: project.pricePerYardDisplay(),
    estimatedMarginPercent: project.estimatedMarginPercent(),
    plantName: project.plant.name,
    totalCostString: CurrencyString({ cur: project.totalCosts }),
    userName: project.user.fullName(),
    status: project.projectStatus.name,
    estimatedVolumeString: project.estimatedVolume.toLocaleString(),
    taxCodeString:
      project.taxCode === null ? null : `${project.taxCode.name} (${project.taxCode.code})`,
    segmentString: project.segment === null ? null : project.segment.name,
    plantDistanceMilesString:
      project.plantDistanceMiles === null ? null : `${project.plantDistanceMiles} mi`,
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='fit-content'
      whiteSpace='nowrap'
      gap='1.25rem'
    >
      <DisplayField
        title='Project Details'
        onEditClick={onProjectDetailsClick}
        value={displayProject}
        displayMatrix={[
          [
            { type: 'default', label: 'Owner', key: 'owner' },
            { type: 'default', label: 'Status', key: 'status' },
          ],
          [
            {
              label: 'Project Winner',
              key: 'winnerName',
              type: 'uri',
              uri: `/companies/${project.winningCompany?.company?.id}`,
            },
            { type: 'default', label: 'Confidence %', key: 'confidenceString' },
          ],
          [
            { type: 'default', label: 'Est. Start Date', key: 'estStartDateString' },
            { type: 'default', label: 'Est. End Date', key: 'estEndDateString' },
          ],
          [
            { type: 'default', label: 'Bid Date', key: 'bidDateString' },
            { type: 'default', label: 'Expiration Date', key: 'expirationDateString' },
          ],
          [
            { type: 'default', label: 'Salesperson', key: 'userName' },
            { type: 'default', label: 'Competitor', key: 'competitor' },
          ],
          [
            { type: 'default', label: 'Tax Code', key: 'taxCodeString' },
            { type: 'default', label: 'Segment', key: 'segmentString' },
          ],
        ]}
      />
      {project.customFields.length > 0 && (
        <DisplayField
          title='Additional Information'
          onEditClick={onCustomFieldsClick}
          // Assemble a display model for our custom fields, where each custom
          // field's "property name" is the ID of the field's definition, and
          // the property value is this project's value for that custom field.
          value={Object.fromEntries(
            project.customFields.map((pcf) => {
              if (pcf.definition.type === 'options') {
                return [
                  pcf.definition.id,
                  pcf.definition.options?.find((opt) => opt.value === pcf.value)?.label ?? null,
                ];
              }
              return [pcf.definition.id, pcf.value];
            }),
          )}
          displayMatrix={_.chunk(
            customFieldDefinitions.items.map(
              (cfd): SingleField<{ [key: string]: string | null }> => {
                const type = cfd.type === 'text' ? 'notes' : 'default';
                return {
                  type,
                  maxWidth: '15rem', // only used if type === 'notes'
                  key: cfd.id,
                  label: cfd.name,
                };
              },
            ),
            2,
          )}
        />
      )}
      <DisplayField
        title='Job Site Address'
        onEditClick={onAddressInformationClick}
        value={displayProject}
        displayMatrix={[
          [
            { type: 'default', label: 'Line 1', key: 'line1' },
            { type: 'default', label: 'Line 2', key: 'line2' },
          ],
          [
            { type: 'default', label: 'Zip/Postal', key: 'postalCode' },
            { type: 'default', label: 'City/Town', key: 'city' },
          ],
          [
            { type: 'default', label: 'State/County', key: 'state' },
            { type: 'default', label: 'Country', key: 'country' },
          ],
        ]}
      />
      <DisplayField
        title='Details'
        onEditClick={onDetailsClick}
        value={displayProject}
        displayMatrix={[
          [
            { type: 'default', label: 'Distance from plant', key: 'plantDistanceMilesString' },
            { type: 'default', label: 'Est. Revenue', key: 'estimatedRevenueString' },
          ],
          [
            { type: 'default', label: 'Est. CUYD', key: 'estimatedVolumeString' },
            { type: 'default', label: 'Est. Margin', key: 'estimatedMarginString' },
          ],
          [
            { type: 'default', label: 'Price per yard', key: 'pricePerYardDisplay' },
            { type: 'default', label: 'Est. Margin %', key: 'estimatedMarginPercent' },
          ],
          [
            {
              label: 'Plant',
              key: 'plantName',
              type: 'uri',
              uri: `/plants/${project.plant.id}`,
            },
            { type: 'default', label: 'Est. Costs', key: 'totalCostString' },
          ],
        ]}
      />
      <DisplayField
        title='Note'
        onEditClick={onNoteClick}
        value={displayProject}
        displayMatrix={[[{ label: '', key: 'notes', type: 'notes', maxWidth: '30rem' }]]}
      />
    </Box>
  );
};
