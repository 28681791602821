import { Box, Chip, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext } from 'react';

import { Activity } from '../../generated-types/Activity/Activity';
import { User } from '../../generated-types/User/User';
import { SlabContext } from '../../SlabContext';
import { FormatDisplayDateTime } from '../../utils/DateHelpers';
import { randomUUID } from '../../utils/UUID';

export type TaskListProps = {
  currentDate: DateTime;
  activities: Activity[];
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActivityID: React.Dispatch<React.SetStateAction<string | null>>;
};

const NoTasks = (): JSX.Element => {
  const theme = useTheme();

  return (
    <ListItem disableGutters key={randomUUID()}>
      <Box
        textAlign='center'
        padding='2rem'
        borderRadius='5px'
        width='100%'
        sx={{
          backgroundColor: theme.palette.SlabGray['50'],
        }}
      >
        No tasks to complete!
      </Box>
    </ListItem>
  );
};

type TaskItemsProps = {
  tasks: Activity[];
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActivityID: React.Dispatch<React.SetStateAction<string | null>>;
};

const TaskItems = ({ tasks, setIsDrawerOpen, setActivityID }: TaskItemsProps): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      {tasks.map((task: Activity): JSX.Element => {
        const secondary = (
          <Box component='span' display='flex' flexDirection='column'>
            <Box component='span'>
              {FormatDisplayDateTime(task.startTime, DateTime.TIME_SIMPLE)}
            </Box>
            {task.assignees.length > 0 && (
              <Box component='span' paddingTop='0.5rem'>
                {task.assignees.map(
                  (user: User): JSX.Element => (
                    <Chip
                      component='span'
                      key={randomUUID()}
                      size='small'
                      label={user.fullName()}
                      sx={{ margin: '0.25rem' }}
                    />
                  ),
                )}
              </Box>
            )}
          </Box>
        );

        return (
          <ListItem disableGutters key={randomUUID()}>
            <Box
              padding='0.5rem 1rem'
              borderLeft={`6px solid ${theme.palette.SlabIndigo['700']}`}
              borderRadius='6px'
              width='100%'
              sx={{
                backgroundColor: theme.palette.SlabGray['50'],
                cursor: 'pointer',
              }}
              onClick={(): void => {
                setActivityID(task.id);
                setIsDrawerOpen(true);
              }}
            >
              <ListItemText
                sx={{
                  '& .MuiListItemText-secondary': {
                    fontSize: '0.875rem',
                  },
                  '& .MuiListItemText-primary': {
                    textDecorationLine: task.isDone() ? 'line-through' : 'none',
                  },
                }}
                primary={task.name}
                secondary={secondary}
              />
            </Box>
          </ListItem>
        );
      })}
    </>
  );
};

export const TaskList = ({
  currentDate,
  activities,
  setIsDrawerOpen,
  setActivityID,
}: TaskListProps): JSX.Element => {
  const theme = useTheme();
  const { userInfo } = useContext(SlabContext);

  // Get tasks for current user, currentDate, ordered by start time.
  // TODO #884: Replace this with a server call.
  const tasks = activities
    .filter((task): boolean => {
      if (!task.assignees.some((u) => u.id === userInfo.user.id)) {
        return false;
      }
      return currentDate.hasSame(task.startTime, 'day');
    })
    .sort((a, b): number => {
      if (a.startTime < b.startTime) {
        return -1;
      }
      if (a.startTime > b.startTime) {
        return 1;
      }
      return 0;
    });

  return (
    <Box
      padding='1rem'
      display='flex'
      border={`1px solid ${theme.palette.SlabPurple['200']}`}
      borderRadius='8px'
      flexDirection='column'
      alignContent='stretch'
      sx={{
        backgroundColor: theme.palette.SlabGray[0],
      }}
    >
      <Box display='flex' alignItems='center'>
        <Typography fontWeight={600} color={theme.palette.SlabGray['800']} paddingRight='1rem'>
          {FormatDisplayDateTime(currentDate, DateTime.DATE_FULL)}
        </Typography>
        <Chip
          sx={{
            color: theme.palette.SlabGray['800'],
            backgroundColor: theme.palette.SlabYellow[500],
          }}
          label={tasks.length}
        />
      </Box>
      <List
        sx={{
          fontSize: '0.875rem',
        }}
      >
        {tasks.length === 0 ? (
          <NoTasks />
        ) : (
          <TaskItems
            tasks={tasks}
            setIsDrawerOpen={setIsDrawerOpen}
            setActivityID={setActivityID}
          />
        )}
      </List>
    </Box>
  );
};
