import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { NewPlant, Plant } from '../../generated-types/Plant/Plant';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { PlantDrawerSections, PlantSection } from './components/PlantDrawerSections';
import {
  FormikPlant,
  NewPlantFromFormik,
  PlantFormikType,
  PlantSchemaFormik,
  PlantSchemaWire,
} from './PlantFormik';

type PlantDrawerProps = Omit<SharedDrawerOverrideProps<Plant>, 'resourceID' | 'onSave'> & {
  plantID: string | null;
  onSuccess: (plant: Plant) => void;
  initialSection?: PlantSection;
  /** @default DateTime.now().year */
  initialForecastYear?: number;
};

const PlantDrawerPage = ({
  plantID,

  setIsOpen,

  onSuccess,
  onError,
  onClose,

  initialSection = 'Plant details*',
  initialForecastYear = DateTime.now().year,

  ensureDefined = DefaultEnsureDefined,
}: PlantDrawerProps): JSX.Element | null => {
  const isNew = plantID === null;

  const createNew = useSlabMutation('POST plant', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT plant by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingPlant,
    isError: isErrorPlant,
    data: maybePlant,
  } = useSlabQuery(
    'GET plant by ID',
    {
      pathParams: {
        id: plantID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingForecasts,
    isError: isErrorForecasts,
    data: forecasts,
  } = useSlabQuery(
    'GET plant forecasts by plant ID',
    {
      pathParams: {
        id: plantID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const isLoading = isLoadingPlant || isLoadingForecasts;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorPlant || isErrorForecasts;

  if (isError) {
    return <div>ERROR</div>;
  }

  const plant = ensureDefined(
    maybePlant === undefined
      ? undefined
      : NewPlant({
          ...maybePlant,
          forecasts,
        }),
  );
  const formikPlant = FormikPlant(plant);

  return (
    <Formik
      validationSchema={PlantSchemaFormik}
      initialValues={formikPlant}
      onSubmit={async (values: PlantFormikType): Promise<void> => {
        const wirePlant = NewPlantFromFormik(values);
        if (formikPlant.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wirePlant,
            },
            schema: PlantSchemaWire,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikPlant.id,
              },
              body: wirePlant,
            },
            schema: PlantSchemaWire,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a plant
          </Typography>
          <PlantDrawerSections
            initialSection={initialSection}
            initialForecastYear={initialForecastYear}
          />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikPlant });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikPlant })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const PlantDrawer = (props: PlantDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <PlantDrawerPage {...props} />
  </SlabDrawer>
);
