import { keepPreviousData, UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { Dispatch, MutableRefObject } from 'react';

import Enums from '../../generated-types/Enums';
import { useSlabQuery, useSlabQueryFetcher } from '../../hooks/useSlabQuery';
import { FilterByOpts, ListURLParams, QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { HashSet } from '../../utils/HashSet';
import {
  MakeImmediateResult,
  MergeAsyncResults,
  PossiblyAsyncResult,
  QueryError,
} from '../../utils/Query';
import { ExcludeFromUnion, NestedKeyOf } from '../../utils/Types';
import { useAsyncReducer } from '../../utils/useAsyncReducer';
import { SortConfig } from './components/SortHelpers';
import {
  Column,
  ColumnConfig,
  ColumnFromConfig,
  HashFilter,
  TableDataModel,
} from './TableDataModel';

export const DEFAULT_QUERY_PARAMS = { page: 0, perPage: 50 };

export type ColumnState<TRow extends { id: string }> = {
  readonly allColumnsByID: ReadonlyMap<string, Column<TRow>>;
  readonly renderedColumns: ReadonlySet<Column<TRow>>;
};
type ColumnStateChangeAction<TRow extends { id: string }> = {
  columnID: NestedKeyOf<TRow>;
  isRendered: boolean;
};

export type ColumnAction<TRow extends { id: string }> =
  | {
      type: 'state';
      state: ColumnStateChangeAction<TRow>;
    }
  | {
      type: 'configs';
      columns: ColumnConfig<TRow, NestedKeyOf<TRow>>[];
    };

const hashColumn = <TRow extends { id: string }>(column: Column<TRow>): string => column.id;

export const ApiTableDataImplementation = <
  TRow extends { id: string },
  RowArgs extends { queryParams: ListURLParams<TRow> },
  TAgg extends { id: string },
  TMore,
>(
  rowsResult: PossiblyAsyncResult<{ rows: TRow[]; count: number }>,
  aggregationsResult: PossiblyAsyncResult<{ [id: string]: TAgg }>,
  moreDataResult: PossiblyAsyncResult<TMore>,
  fetchAllRows: () => Promise<TRow[]>,
  makeColumnConfigs: (moreData: TMore) => ColumnConfig<TRow, NestedKeyOf<TRow>>[],
  [queryArgs, queryArgsRef, setQueryArgs]: [RowArgs, MutableRefObject<RowArgs>, Dispatch<RowArgs>],
  setUpAsyncReducer: typeof useAsyncReducer<
    ColumnState<TRow>,
    ColumnAction<TRow>,
    ColumnConfig<TRow, NestedKeyOf<TRow>>[]
  >,
  initialSortBy?: ListURLParams<TRow>['sortBy'],
): TableDataModel<TRow, TAgg> => {
  const [columnData, columnDataRef, dispatchColumnChange] = setUpAsyncReducer(
    (state: ColumnState<TRow>, action: ColumnAction<TRow>): ColumnState<TRow> => {
      switch (action.type) {
        case 'state': {
          const renderedColumnIDs = new Set([...state.renderedColumns].map((col) => col.id));
          if (action.state.isRendered) {
            renderedColumnIDs.add(action.state.columnID);
          } else {
            renderedColumnIDs.delete(action.state.columnID);
          }
          return {
            allColumnsByID: state.allColumnsByID,
            renderedColumns: new HashSet(
              hashColumn,
              [...state.allColumnsByID.values()].filter((col) => renderedColumnIDs.has(col.id)),
            ),
          };
        }
        case 'configs': {
          const allColumnsByID = new Map(
            action.columns.map(ColumnFromConfig).map((c) => [c.id, c]),
          );
          return {
            allColumnsByID,
            renderedColumns: new HashSet(
              hashColumn,
              [...state.renderedColumns].filter((c) => allColumnsByID.has(c.id)),
            ),
          };
        }
        default:
          // do nothing
          return state;
      }
    },
    makeColumnConfigs(moreDataResult.data),
    (configs: ColumnConfig<TRow, NestedKeyOf<TRow>>[]): ColumnState<TRow> => {
      const allColumns = configs.map(ColumnFromConfig);
      const renderedColumns = configs
        .filter((config) => config.isDisplayed)
        .map((config) => allColumns.find((c) => c.id === config.id)) as Column<TRow>[];
      return {
        allColumnsByID: new Map(allColumns.map((c) => [c.id, c])),
        renderedColumns: new HashSet(hashColumn, renderedColumns),
      };
    },
  );
  // Update active column list if it has changed.
  if (!moreDataResult.isLoading) {
    const configs = makeColumnConfigs(moreDataResult.data);
    // When the moreDataResult has loaded, if the set of column configs produced by their factory has changed, then
    // asynchronously update the set of columns.  This is asynchronous to defer the filter change until immediately
    // after the current rendering pass finishes, because React assumes this change might affect the containing node's
    // rendering and that containing node has already been (partially) rendered by the time we get here.
    if (configs.map((c) => c.id).some((id) => !columnDataRef.current.allColumnsByID.has(id))) {
      dispatchColumnChange({ type: 'configs', columns: configs });
    }
  }

  return {
    get rowCount(): PossiblyAsyncResult<number> {
      // HACK: if we fail to load a page, return a total that includes the current page. Otherwise the MUI pagination
      // component displays "0-0 of 0" and only allows navigation back to the very first page, which is super
      // annoying if the HTTP request for a much later page errors out.
      if (rowsResult.isError && queryArgs.queryParams.page > 0) {
        const count = queryArgs.queryParams.perPage * (queryArgs.queryParams.page + 1);
        return { ...rowsResult, data: count };
      }
      return { ...rowsResult, data: rowsResult.data.count };
    },
    get allColumns(): ReadonlySet<Column<TRow>> {
      return new Set([...columnData.allColumnsByID.values()]);
    },
    get renderedColumns(): ReadonlySet<Column<TRow>> {
      return new HashSet(hashColumn, [...columnData.renderedColumns]);
    },
    async setIsColumnRendered(columnID: NestedKeyOf<TRow>, isRendered: boolean): Promise<void> {
      await dispatchColumnChange({ type: 'state', state: { columnID, isRendered } });
    },
    get aggregations(): PossiblyAsyncResult<{ [id: string]: TAgg }> {
      return aggregationsResult;
    },
    get pageIndex(): number {
      return queryArgs.queryParams.page ?? 0;
    },
    get currentPage(): PossiblyAsyncResult<TRow[]> {
      return { ...rowsResult, data: rowsResult.data.rows };
    },
    async setPageIndex(pageIndex: number): Promise<void> {
      const args = queryArgsRef.current;
      await setQueryArgs({ ...args, queryParams: { ...args.queryParams, page: pageIndex } });
    },
    async getRowsForAllPagesWithPotentiallyPoorPerformance(): Promise<TRow[]> {
      return fetchAllRows();
    },
    get sortConfig(): SortConfig<TRow> {
      return (
        queryArgs.queryParams.sortBy ?? {
          direction: Enums.SortDirection.Ascending,
          name: [...this.renderedColumns][0]?.id ?? 'id',
        }
      );
    },
    get initialSortConfig(): SortConfig<TRow> {
      return (
        initialSortBy ?? {
          direction: Enums.SortDirection.Ascending,
          name: [...this.renderedColumns][0]?.id ?? 'id',
        }
      );
    },
    async setSortConfig(sortConfig: SortConfig<TRow>): Promise<void> {
      const { queryParams } = queryArgsRef.current;
      if (
        sortConfig.direction === queryParams.sortBy?.direction &&
        sortConfig.name === queryParams.sortBy?.name
      ) {
        return;
      }
      const args = queryArgsRef.current;
      await setQueryArgs({
        ...args,
        queryParams: { ...args.queryParams, sortBy: sortConfig, page: 0 },
      });
    },
    get rowCountPerPage(): number {
      return queryArgs.queryParams.perPage;
    },
    async setRowCountPerPage(rowCountPerPage: number): Promise<void> {
      const args = queryArgsRef.current;
      await setQueryArgs({
        ...args,
        queryParams: { ...args.queryParams, perPage: rowCountPerPage },
      });
    },
    getFilter(
      hashData: ExcludeFromUnion<FilterByOpts<TRow>, 'value'>,
    ): FilterByOpts<TRow> | undefined {
      const hash = HashFilter(hashData);
      const filterBy = queryArgs.queryParams.filterBy ?? [];
      return filterBy.find((filter) => hash === HashFilter(filter)) as
        | FilterByOpts<TRow>
        | undefined;
    },
    /** Add filters, or update reference values for existing filters with the same operation and paths. */
    async addFilters(...filters: FilterByOpts<TRow>[]): Promise<void> {
      await this.replaceFilters([], filters);
    },
    /** Remove filters, matching on operation and path. */
    async removeFilters(...filters: FilterByOpts<TRow>[]): Promise<void> {
      await this.replaceFilters(filters, []);
    },
    async clearFilters(): Promise<void> {
      const args = queryArgsRef.current;
      setQueryArgs({ ...args, queryParams: { ...args.queryParams, filterBy: [], page: 0 } });
    },
    async replaceFilters(
      oldFilters: FilterByOpts<TRow>[],
      newFilters: FilterByOpts<TRow>[],
    ): Promise<void> {
      const args = queryArgsRef.current;
      const originalFilterBy = args.queryParams.filterBy ?? [];
      const filterBy = new HashSet<FilterByOpts<TRow>>(HashFilter<TRow>, [
        ...originalFilterBy,
      ] as FilterByOpts<TRow>[]);
      oldFilters.forEach((f) => {
        filterBy.delete(f);
      });
      newFilters.forEach((f) => {
        filterBy.add(f);
      });
      // Don't set new filters if the newly computed filters exactly match the previous ones.
      // In addition to being a performance optimization (skipping unnecessary React rendering passes),
      // this avoids infinite loops given the DataTable's current approach to managing tab filters.
      if (originalFilterBy.length === filterBy.size) {
        const hasChanges = originalFilterBy.some((original) => {
          const updated = filterBy.get(original);
          if (updated === undefined) {
            return true;
          }
          return Object.entries(original).some(
            ([k, v]) => v !== updated[k as keyof FilterByOpts<TRow>],
          );
        });
        if (!hasChanges) {
          return;
        }
      }
      await setQueryArgs({
        ...args,
        queryParams: { ...args.queryParams, filterBy: [...filterBy], page: 0 },
      });
    },
  };
};

export type ApiTableDataWithMoreParams<
  TRow extends { id: string },
  RowKey extends keyof QueryRouteBarrelTypes,
  RowBarrel extends QueryRouteBarrelTypes[RowKey],
  TAgg extends { id: string },
  AggKey extends keyof QueryRouteBarrelTypes,
  AggBarrel extends QueryRouteBarrelTypes[AggKey],
  TMore,
> = {
  rowsRouteKey: RowKey;
  rowsQueryOptions?: RowBarrel extends { options: {} } ? RowBarrel['options'] : any;
  initialSortBy: ListURLParams<TRow>['sortBy'];
  initialFilterBy?: ListURLParams<TRow>['filterBy'];
  aggregationsRouteKey: AggKey;
  aggregationsQueryOptions?: AggBarrel extends { options: {} } ? AggBarrel['options'] : any;
  moreDataResult: PossiblyAsyncResult<TMore>;
  extractRows: (data: RowBarrel['returns'], moreData: TMore) => TRow[];
  extractAggregations: (data: AggBarrel['returns']) => { [id: string]: TAgg };
  makeColumnConfigs: (moreData: TMore) => ColumnConfig<TRow, NestedKeyOf<TRow>>[];
  makeAggregationArgs?: (rowsQueryArgs: RowBarrel['args']) => AggBarrel['args'];
} & (RowBarrel['args'] extends { pathParams: {} }
  ? { rowsPathParams: RowBarrel['args']['pathParams'] }
  : { rowsPathParams?: any });
export type ApiTableDataParams<
  TRow extends { id: string },
  RowKey extends keyof QueryRouteBarrelTypes,
  RowBarrel extends QueryRouteBarrelTypes[RowKey],
  TAgg extends { id: string },
  AggKey extends keyof QueryRouteBarrelTypes,
  AggBarrel extends QueryRouteBarrelTypes[AggKey],
> = Omit<
  ApiTableDataWithMoreParams<TRow, RowKey, RowBarrel, TAgg, AggKey, AggBarrel, undefined>,
  'moreDataResult'
>;
export type ApiTableDataWithoutAggregationsParams<
  TRow extends { id: string },
  RowKey extends keyof QueryRouteBarrelTypes,
  RowBarrel extends QueryRouteBarrelTypes[RowKey],
> = Omit<
  ApiTableDataWithMoreParams<TRow, RowKey, RowBarrel, any, RowKey, RowBarrel, undefined>,
  | 'moreDataResult'
  | 'aggregationsRouteKey'
  | 'aggregationsQueryOptions'
  | 'extractAggregations'
  | 'makeAggregationArgs'
>;

const generateQueryParams = <TRow extends { id: string }>(
  initialSortBy: ListURLParams<TRow>['sortBy'],
  initialFilterBy: ListURLParams<TRow>['filterBy'] = [],
): ListURLParams<TRow> => {
  const params: ListURLParams<TRow> = {
    ...DEFAULT_QUERY_PARAMS,
    sortBy: initialSortBy,
  };
  if (initialFilterBy.length > 0) {
    params.filterBy = initialFilterBy;
  }
  return params;
};

export const useApiTableDataWithMore = <
  TRow extends { id: string },
  RowKey extends keyof QueryRouteBarrelTypes,
  RowBarrel extends QueryRouteBarrelTypes[RowKey] & { returns: { count: number } },
  TAgg extends { id: string },
  AggKey extends keyof QueryRouteBarrelTypes,
  AggBarrel extends QueryRouteBarrelTypes[AggKey],
  TMore,
>({
  rowsRouteKey,
  rowsPathParams = {},
  initialSortBy,
  initialFilterBy = [],
  aggregationsRouteKey,
  moreDataResult,
  extractRows,
  extractAggregations,
  makeColumnConfigs,
  makeAggregationArgs = (rowsQueryArgs): AggBarrel['args'] =>
    _.pick(rowsQueryArgs, 'queryParams.filterBy'),
  ...optionalParams
}: ApiTableDataWithMoreParams<
  TRow,
  RowKey,
  RowBarrel,
  TAgg,
  AggKey,
  AggBarrel,
  TMore
>): TableDataModel<TRow, TAgg> => {
  type RowArgsWithQueryParams = RowBarrel['args'] & {
    queryParams: ListURLParams<TRow>;
  };

  const [queryArgs, queryArgsRef, setQueryArgs] = useAsyncReducer(
    (
      previousArgs: RowArgsWithQueryParams,
      nextArgs: RowBarrel['args'] & { queryParams: ListURLParams<TRow> },
    ): RowArgsWithQueryParams => {
      const newState: any = {
        ...previousArgs,
        ...nextArgs,
        queryParams: { ...previousArgs.queryParams, ...nextArgs.queryParams },
      };
      if ((newState.queryParams.filterBy ?? []).length === 0) {
        delete newState.queryParams.filterBy;
      }
      return newState;
    },
    {
      pathParams: rowsPathParams,
      queryParams: generateQueryParams(initialSortBy, initialFilterBy),
    },
    (initialArgs: RowArgsWithQueryParams): RowArgsWithQueryParams => initialArgs,
  );

  const rawRowResult: UseQueryResult<RowBarrel['returns'], QueryError> = useSlabQuery(
    rowsRouteKey,
    queryArgs,
    {
      ...optionalParams.rowsQueryOptions,
      placeholderData: keepPreviousData,
    },
  );
  const rowsResult: PossiblyAsyncResult<{ rows: TRow[]; count: number }> = {
    ...MergeAsyncResults({ rawRowResult, moreDataResult }),
    data: {
      rows:
        rawRowResult.data === undefined ||
        (moreDataResult.data === undefined && moreDataResult.status !== 'success')
          ? []
          : extractRows(rawRowResult.data, moreDataResult.data),
      count: rawRowResult.data?.count ?? 0,
    },
  };

  const rawAggregationResult = useSlabQuery(aggregationsRouteKey, makeAggregationArgs(queryArgs), {
    ...optionalParams.aggregationsQueryOptions,
    placeholderData: keepPreviousData,
  });
  const aggregationsResult: PossiblyAsyncResult<{ [id: string]: TAgg }> = {
    ...rawAggregationResult,
    data:
      rawAggregationResult.data === undefined ? {} : extractAggregations(rawAggregationResult.data),
  };

  const fetchPromisedRows = useSlabQueryFetcher();
  const fetchAllRows = async (): Promise<TRow[]> => {
    const queryParams = _.omit(queryArgs.queryParams, ['page', 'perPage']);
    const args: any = { ...queryArgs, queryParams };
    const data = (await fetchPromisedRows(
      rowsRouteKey,
      args,
      optionalParams.rowsQueryOptions,
    )) as RowBarrel['returns'];
    return extractRows(data, moreDataResult.data);
  };

  return ApiTableDataImplementation(
    rowsResult,
    aggregationsResult,
    moreDataResult,
    fetchAllRows,
    makeColumnConfigs,
    [queryArgs, queryArgsRef, setQueryArgs],
    /* setUpColReducer= */ useAsyncReducer<
      ColumnState<TRow>,
      ColumnAction<TRow>,
      ColumnConfig<TRow, NestedKeyOf<TRow>>[]
    >,
    initialSortBy,
  );
};

export const useApiTableData = <
  TRow extends { id: string },
  RowKey extends keyof QueryRouteBarrelTypes,
  RowBarrel extends QueryRouteBarrelTypes[RowKey] & { returns: { count: number } },
  TAgg extends { id: string },
  AggKey extends keyof QueryRouteBarrelTypes,
  AggBarrel extends QueryRouteBarrelTypes[AggKey],
>(
  input: ApiTableDataParams<TRow, RowKey, RowBarrel, TAgg, AggKey, AggBarrel>,
): TableDataModel<TRow, TAgg> =>
  useApiTableDataWithMore({
    ...input,
    moreDataResult: MakeImmediateResult(undefined),
  } as ApiTableDataWithMoreParams<TRow, RowKey, RowBarrel, TAgg, AggKey, AggBarrel, undefined>);

export const useApiTableDataWithoutAggregations = <
  TRow extends { id: string },
  RowKey extends keyof QueryRouteBarrelTypes,
  RowBarrel extends QueryRouteBarrelTypes[RowKey] & {
    returns: { count: number };
    options: { enabled: boolean };
  },
>(
  input: ApiTableDataWithoutAggregationsParams<TRow, RowKey, RowBarrel>,
): TableDataModel<TRow, { id: string }> =>
  useApiTableDataWithMore({
    ...input,
    moreDataResult: MakeImmediateResult(undefined),
    aggregationsRouteKey: input.rowsRouteKey,
    aggregationsQueryOptions: {
      ...(input.rowsQueryOptions ?? {}),
      enabled: false,
    } as RowBarrel['options'],
    extractAggregations: () => ({}),
  } as ApiTableDataWithMoreParams<TRow, RowKey, RowBarrel, any, RowKey, RowBarrel, undefined>);
