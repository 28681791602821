import { Box, Divider, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import { useContext } from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { Input } from '../../components/Input/Input';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../../components/LookupInput/LookupInputSharedComponents';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { Contact } from '../../generated-types/Contact/Contact';
import Enums from '../../generated-types/Enums';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { DefaultEnsureDefined, lookups } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { ContactFormikType, ContactSchema, FormikContact } from './ContactFormik';

type ContactDrawerProps = SharedDrawerOverrideProps<Contact>;

const ContactDrawerPage = ({
  resourceID,
  setIsOpen,
  onSave,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: ContactDrawerProps): JSX.Element | null => {
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const isNew = resourceID === null;

  const createNew = useSlabMutation('POST contact', {
    onSuccess: (data) => onSave(data),
    onError,
  });
  const updateExisting = useSlabMutation('PUT contact by ID', {
    onSuccess: (data) => onSave(data),
    onError,
  });

  const {
    isLoading: isLoadingContact,
    isError: isErrorContact,
    data: maybeContact,
  } = useSlabQuery(
    'GET contact by ID',
    {
      pathParams: {
        id: resourceID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const isLoading = isLoadingContact;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorContact;

  if (isError) {
    return <div>ERROR</div>;
  }

  const contact = ensureDefined(maybeContact);
  const formikContact = FormikContact(contact, usesDispatchCustomer);

  return (
    <Formik
      validationSchema={ContactSchema}
      initialValues={formikContact}
      onSubmit={async (values: ContactFormikType): Promise<void> => {
        // TODO #2219 Need a NewContactFromForm wrapper for this case
        const wireContact = new Contact(_.merge(Contact.zero(), values));
        if (formikContact.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireContact,
            },
            schema: ContactSchema,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikContact.id,
              },
              body: wireContact,
            },
            schema: ContactSchema,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a contact
          </Typography>
          <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
            <Typography variant='h2'>Personal details</Typography>
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item xs={6}>
                <Input name='firstName' label='First name*' />
              </Grid>
              <Grid item xs={6}>
                <Input name='lastName' label='Last name*' />
              </Grid>
            </Grid>
            {/* InputDropdowns must be in their own grid to prevent wrap over-responsiveness. */}
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item xs={6}>
                <ApiLookupInput
                  formState={formikBag.values}
                  name='company'
                  route={{
                    barrel: 'GET companies',
                    args: ConstructListQueryParams,
                    transform: (companyList): LookupInputOption[] =>
                      lookups({
                        ...companyList,
                        label: (c) => c.name,
                        sublabels: (c) => c.lookupSublabels(usesDispatchCustomer),
                      }),
                  }}
                  label='Company name*'
                />
              </Grid>
              <Grid item xs={6}>
                <Input name='jobTitle' label='Job title' />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
            <Typography variant='h2'>Contact details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input name='emailAddress' label='Email' />
              </Grid>
              <Grid item xs={6}>
                <Input name='phoneNumber' label='Phone number' type='tel' />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Input name='notes' label='Notes' type='textarea' />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikContact });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikContact })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const ContactDrawer = (props: ContactDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <ContactDrawerPage {...props} />
  </SlabDrawer>
);
