import { MutateOptions, UseMutationResult } from '@tanstack/react-query';
import { FormikProps, getIn, useFormikContext } from 'formik';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { numericFormatter } from 'react-number-format';
import { SetNonNullable } from 'type-fest';

import { Address } from '../generated-types/Address/Address';
import { Cost } from '../generated-types/Cost/Cost';
import { CostRule } from '../generated-types/CostRule/CostRule';
import { Currency, NewCurrency } from '../generated-types/Currency/Currency';
import { DeliveryCost } from '../generated-types/DeliveryCost/DeliveryCost';
import Enums from '../generated-types/Enums';
import { Unit } from '../generated-types/generated-enums';
import { QuotePriceEscalation } from '../generated-types/QuotePriceEscalation/QuotePriceEscalation';
import { UpdateType } from '../hooks/useSlabMutation';
import { DomainObject, MutationRouteBarrelTypes } from './ApiClient';
import { CostString, NullableCostString } from './Currency';
import { ZERO_DURATION_STRING } from './DateHelpers';
import { QueryError } from './Query';
import { NestedKeyOf, NestedKeyOfValue, PathValue } from './Types';
import { NIL_UUID } from './UUID';
import {
  YupSchemaCostType,
  YupSchemaDeliveryCostsType,
  YupSchemaNullableCostType,
  YupSchemaNullableCurrencyType,
  YupSchemaNullableDeliveryCostsType,
  YupSchemaQuotePriceEscalationType,
} from './YupHelpers';

/**
 * This file contains Yup <=> Formik validation helper types.
 * They are meant to be used inside of `{Type}Formik` files to reduce
 * overhead if they are a shared type.
 */

export const FormikAddress = (address: Address | undefined): DomainObject<Address> => ({
  line1: address?.line1 ?? null,
  line2: address?.line2 ?? null,
  city: address?.city ?? null,
  state: address?.state ?? '',
  postalCode: address?.postalCode ?? null,
  country: address?.country ?? 'USA',
  latitude: address?.latitude ?? null,
  longitude: address?.longitude ?? null,
});

/** Helper to translate the `number` string into thousands-friendly format. */
export const FormikCurrency = (currency: Currency | undefined): DomainObject<Currency> => {
  // A user may start typing a negative value, so consider it empty until a number is entered.
  const numberValue = currency?.number === '-' ? '' : (currency?.number ?? '');
  return {
    number: numericFormatter(numberValue, { thousandSeparator: true }),
    currency: currency?.currency ?? 'USD',
  };
};

/** Helper to translate the `number` string into thousands-friendly format. */
export const FormikNullableCurrency = (
  currency: Currency | null | undefined,
): YupSchemaNullableCurrencyType => (currency === null ? null : FormikCurrency(currency));

/** Helper to translate the `number` string into thousands-friendly format. */
export const FormikCost = (cost: Partial<Cost> | undefined): YupSchemaCostType => ({
  amount: FormikCurrency(cost?.amount),
  unit: cost?.unit ?? Enums.Unit.CuYd,
});

/** Helper to translate the `number` string into thousands-friendly format. */
export const FormikNullableCost = (
  cost: Partial<Cost> | null | undefined,
): YupSchemaNullableCostType => (cost === null ? null : FormikCost(cost));

export const FormikDeliveryCosts = (
  deliveryCosts: Partial<DeliveryCost> | undefined,
): SetNonNullable<YupSchemaDeliveryCostsType> => {
  const drivingTimeAdjustmentPercentage = String(
    Number(deliveryCosts?.drivingTimeAdjustmentPercentage ?? '0.000') * 100,
  );
  const unengagedTimeAdjustmentPercentage = String(
    Number(deliveryCosts?.unengagedTimeAdjustmentPercentage ?? '0.000') * 100,
  );
  return {
    deliveryCostOverride:
      deliveryCosts?.deliveryCostOverride === null
        ? {
            amount: {
              number: '',
              currency: 'USD',
            },
            unit: Enums.Unit.CuYd,
          }
        : FormikCost(deliveryCosts?.deliveryCostOverride),

    perMinCost: FormikCurrency(deliveryCosts?.perMinCost ?? Currency.zero()),
    loadTime: deliveryCosts?.loadTime?.toISO() ?? ZERO_DURATION_STRING,
    loadedInYardTime: deliveryCosts?.loadedInYardTime?.toISO() ?? ZERO_DURATION_STRING,
    driveToJobTime: deliveryCosts?.driveToJobTime?.toISO() ?? ZERO_DURATION_STRING,
    waitTime: deliveryCosts?.waitTime?.toISO() ?? ZERO_DURATION_STRING,
    pourTime: deliveryCosts?.pourTime?.toISO() ?? ZERO_DURATION_STRING,
    unloadedAtJobTime: deliveryCosts?.unloadedAtJobTime?.toISO() ?? ZERO_DURATION_STRING,
    driveToPlantTime: deliveryCosts?.driveToPlantTime?.toISO() ?? ZERO_DURATION_STRING,
    drivingTimeAdjustmentPercentage,
    unengagedTimeAdjustmentPercentage,
    fuelVolumeUnit: deliveryCosts?.fuelVolumeUnit ?? Unit.Gal,
    fuelCostPerUnit: FormikCurrency(deliveryCosts?.fuelCostPerUnit ?? Currency.zero()),
    driveDistanceUnit: deliveryCosts?.driveDistanceUnit ?? Unit.Mi,
    driveDistancePerFuelVolume: deliveryCosts?.driveDistancePerFuelVolume ?? '0',
    driveDistanceToJob: deliveryCosts?.driveDistanceToJob ?? '0',
    driveDistanceToPlant: deliveryCosts?.driveDistanceToPlant ?? '0',
    loadSizeUnit: deliveryCosts?.loadSizeUnit ?? Unit.CuYd,
    loadSize: deliveryCosts?.loadSize ?? '1',

    displayOnly: {
      totalDeliveryCost: NullableCostString({ cost: deliveryCosts?.totalDeliveryCost ?? null }),
    },
  };
};

export const FormikNullableDeliveryCosts = (
  deliveryCosts: Partial<DeliveryCost> | null | undefined,
): YupSchemaNullableDeliveryCostsType => ({
  deliveryCostOverride:
    deliveryCosts?.deliveryCostOverride === null
      ? null
      : FormikCost(deliveryCosts?.deliveryCostOverride),

  perMinCost: FormikCurrency(deliveryCosts?.perMinCost ?? NewCurrency({ number: '' })),
  loadTime: deliveryCosts?.loadTime?.toISO() ?? null,
  loadedInYardTime: deliveryCosts?.loadedInYardTime?.toISO() ?? null,
  driveToJobTime: deliveryCosts?.driveToJobTime?.toISO() ?? null,
  waitTime: deliveryCosts?.waitTime?.toISO() ?? null,
  pourTime: deliveryCosts?.pourTime?.toISO() ?? null,
  unloadedAtJobTime: deliveryCosts?.unloadedAtJobTime?.toISO() ?? null,
  driveToPlantTime: deliveryCosts?.driveToPlantTime?.toISO() ?? null,
  drivingTimeAdjustmentPercentage: deliveryCosts?.drivingTimeAdjustmentPercentage ?? null,
  unengagedTimeAdjustmentPercentage: deliveryCosts?.unengagedTimeAdjustmentPercentage ?? null,
  fuelVolumeUnit: deliveryCosts?.fuelVolumeUnit ?? null,
  fuelCostPerUnit: deliveryCosts?.fuelCostPerUnit ?? null,
  driveDistanceUnit: deliveryCosts?.driveDistanceUnit ?? null,
  driveDistancePerFuelVolume: deliveryCosts?.driveDistancePerFuelVolume ?? null,
  driveDistanceToJob: deliveryCosts?.driveDistanceToJob ?? null,
  driveDistanceToPlant: deliveryCosts?.driveDistanceToPlant ?? null,
  loadSizeUnit: deliveryCosts?.loadSizeUnit ?? null,
  loadSize: deliveryCosts?.loadSize ?? null,

  displayOnly: {
    totalDeliveryCost:
      deliveryCosts?.totalDeliveryCost === undefined
        ? null
        : CostString({ cost: deliveryCosts.totalDeliveryCost }),
  },
});

/** Helper to format phone number strings from `tel` inputs. */
export const FormikTel = (tel: string | undefined): string => {
  if (tel === undefined) {
    return '';
  }
  let parsed;
  try {
    parsed = parsePhoneNumberWithError(tel, 'US');
  } catch {
    return tel;
  }
  return parsed.formatInternational();
};

export const FormikCostRule = (cr: CostRule | null | undefined): DomainObject<CostRule> => ({
  kind: cr?.kind ?? Enums.CostRuleKind.Ratio,
  flatChange: FormikNullableCurrency(
    cr?.flatChange ??
      NewCurrency({
        number: '',
        currency: 'USD',
      }),
  ),
  markupRatio:
    cr !== null && cr !== undefined && cr.markupRatio !== null && cr.markupRatio !== ''
      ? String(parseFloat((parseFloat(cr.markupRatio) * 100).toFixed(3)))
      : '',
});

export const QuotePriceEscalationToFormik = (
  qpe: QuotePriceEscalation,
): YupSchemaQuotePriceEscalationType => ({
  escalationDate: qpe.escalationDate.toISO() ?? '',
  changeRatio:
    qpe !== null && qpe !== undefined && qpe.changeRatio !== null && qpe.changeRatio !== ''
      ? String(parseFloat((parseFloat(qpe.changeRatio) * 100).toFixed(3)))
      : '',
  flatChange: qpe.flatChange,
  kind: qpe.kind,
});

export const SetFormikValue = <T extends Record<string, any>, P extends NestedKeyOf<T>>(
  formikBag: FormikProps<T>,
  path: P, // TODO: add support for setting non-leaf properties
  value: PathValue<T, P>,
  shouldValidate?: boolean,
): void => {
  formikBag.setFieldValue(path, value, shouldValidate);
};

/**
 * Heads up, if this function is called while attempting to clear form values
 * at an array-index level, it is suggested to avoid using this. More often than not,
 * initial empty form state values that use arrays will initialize it to empty arrays.
 * That leads to unfound keys, and setting field values to be undefined which may have
 * unexpected results.
 */
export const ClearFormikFields = <T extends Record<string, any>>(
  formikBag: FormikProps<T>,
  emptyFormikObj: NestedKeyOfValue<T, NestedKeyOf<T>>,
  ...keys: NestedKeyOf<T>[]
): any => {
  const clearedValues = keys.map((key) => {
    const gottenValue = getIn(emptyFormikObj, key);
    if (gottenValue === undefined) {
      LogRocket.warn('Attempted to clear formik value but found no matching key', {
        key,
        availableKeys: Object.keys(formikBag.values as any),
      });
    }

    // We still allow setting the field;
    // 1. It might still work and formik connections are "incorrect correctly".
    // 2. If it is not connected properly, it won't impact the schema validation.
    SetFormikValue(formikBag, key, gottenValue);
    return gottenValue;
  });
  return clearedValues;
};

type OnSubmitHandlerType<
  TFormikType extends Record<'id', string>,
  TCreateKey extends keyof MutationRouteBarrelTypes,
  TCreateArgs extends MutationRouteBarrelTypes[TCreateKey]['args'],
  TCreateReturn extends MutationRouteBarrelTypes[TCreateKey]['returns'],
  TUpdateKey extends keyof MutationRouteBarrelTypes,
  TUpdateArgs extends MutationRouteBarrelTypes[TUpdateKey]['args'],
  TUpdateReturn extends MutationRouteBarrelTypes[TUpdateKey]['returns'],
> = {
  values: TFormikType;
  // This assumes that the `body` of both create & update are the same.
  formikToWire: (v: TFormikType) => TCreateArgs['body'];
  wireSchema: any;

  createNew: UseMutationResult<TCreateReturn, QueryError, UpdateType<TCreateArgs>, unknown>;
  createNewOptions?: MutateOptions<TCreateReturn, QueryError, UpdateType<TCreateArgs>, unknown>;

  updateExisting: UseMutationResult<TUpdateReturn, QueryError, UpdateType<TUpdateArgs>, unknown>;
  updateExistingOptions?: MutateOptions<
    TUpdateReturn,
    QueryError,
    UpdateType<TUpdateArgs>,
    unknown
  >;

  /**
   * If the current formik `id` value is NIL_UUID, it will create new.
   * This is optional additional boolean logic to determine to use the create or update call.
   * @default
   * values.id === NIL_UUID
   */
  shouldCreateNew?: boolean;
};

/**
 * Every time we have a form submission, the shape of actions looks the same so this removes
 * that boilerplate code.
 *
 * This also promotes the enforcement of an async Promise<void> call, which will allow formik
 * to handle form state management under-the-hood without our need for concern.
 *
 * 1. Transform the formik-type values to a wire-friendly format via `formikToWire`.
 * 2. Deterministically choose whether we will create or update.
 * 3. Use the formatted value as well as the provided wireSchema to create/update the value.
 */
export const onSubmitHandler = async <
  TFormikType extends Record<'id', string>,
  TCreateKey extends keyof MutationRouteBarrelTypes,
  TCreateArgs extends MutationRouteBarrelTypes[TCreateKey]['args'],
  TCreateReturn extends MutationRouteBarrelTypes[TCreateKey]['returns'],
  TUpdateKey extends keyof MutationRouteBarrelTypes,
  TUpdateArgs extends MutationRouteBarrelTypes[TUpdateKey]['args'],
  TUpdateReturn extends MutationRouteBarrelTypes[TUpdateKey]['returns'],
>({
  values,
  formikToWire,
  wireSchema,

  createNew,
  createNewOptions,

  updateExisting,
  updateExistingOptions,

  shouldCreateNew = values.id === NIL_UUID,
}: OnSubmitHandlerType<
  TFormikType,
  TCreateKey,
  TCreateArgs,
  TCreateReturn,
  TUpdateKey,
  TUpdateArgs,
  TUpdateReturn
>): Promise<void> => {
  const wireObj = formikToWire(values);

  if (shouldCreateNew) {
    createNew.mutate(
      {
        args: {
          body: wireObj,
          // NOTE: the type of wireObj is type-constrained at the callsite so this will always be the right type.
        } as any,
        schema: wireSchema,
      },
      createNewOptions,
    );
  } else {
    updateExisting.mutate(
      {
        args: {
          pathParams: {
            id: values.id,
          },
          body: wireObj,
          // NOTE: the type of wireObj is type-constrained at the callsite so this will always be the right type.
        } as any,
        schema: wireSchema,
      },
      updateExistingOptions,
    );
  }
};

/**
 * FormErrorNotification is used within a <Formik /> context to notify LogRocket
 * if there are validation errors upon form submission.
 */
export const FormErrorNotification = (): null => {
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      LogRocket.warn('Form validation errors when user submits form:', errors);
    }
  }, [isSubmitting, isValid, isValidating]);

  return null;
};
