import { Box, Button, Chip, ChipOwnProps, Tooltip, Typography, useTheme } from '@mui/material';
import LogRocket from 'logrocket';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonMenu, ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig, TableDataModel } from '../../components/DataTable/TableDataModel';
import { useLocalTableData } from '../../components/DataTable/useLocalTableData';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { MarkAsSentAndShowToast, NylasFlyout } from '../../components/Nylas/NylasFlyout';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { NewQuote, Quote } from '../../generated-types/Quote/Quote';
import { QuotePolicyThreshold } from '../../generated-types/QuotePolicyThreshold/QuotePolicyThreshold';
import { QuotePolicyViolation } from '../../generated-types/QuotePolicyViolation/QuotePolicyViolation';
import { NewQuoteStatus, QuoteStatus } from '../../generated-types/QuoteStatus/QuoteStatus';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { convertExternalID } from '../../utils/ExternalIDHelpers';
import { MergeAsyncResults } from '../../utils/Query';
import { NIL_UUID } from '../../utils/UUID';
import { YupEnum } from '../../utils/YupHelpers';
import { AdditionalProductTable } from './components/AdditionalProductTable';
import { MainProductTable } from './components/MainProductTable';
import { PriceEscalationTable } from './components/PriceEscalationTable';
import { QuoteSection } from './components/QuoteDrawerSections';
import { CanEditCurrentRevision, makeDefaultFormikQuote } from './components/QuoteDrawerUtilities';
import { QuotePdfModal } from './components/QuotePdfModal';
import { QuoteDrawer } from './QuoteDrawer';
import { QuoteSubmitType } from './QuoteFormik';
import { QuoteStatusDrawer } from './QuoteStatusDrawer';

/**
 * DispatchCustomer is the user-facing sub-section indicating whether
 * a company has a valid dispatch customer or not, and how that impacts
 * the quote export process.
 */
const DispatchCustomer = ({ quote }: { quote: Quote }): JSX.Element => {
  const theme = useTheme();

  // Connected to dispatch customer
  if (quote.company !== null && quote.company.dispatchCustomer !== null) {
    return (
      <Box sx={{ color: theme.palette.success.dark }}>
        {quote.company.dispatchCustomer.name} ({quote.company.dispatchCustomer.code})
      </Box>
    );
  }

  // Disconnected from dispatch customer
  return (
    <Tooltip
      enterDelay={200}
      title='A dispatch customer must be linked before a quote may be exported.'
      placement='bottom'
      arrow
    >
      <Box sx={{ color: theme.palette.error.dark }}>(no linked dispatch customer)</Box>
    </Tooltip>
  );
};

type PolicyViolationAndQuoteStatus = {
  id: string;
  quoteID: string;
  quoteStatus: QuoteStatus;
  violation: QuotePolicyViolation;
};

/**
 * FormatPolicyViolationState renders the approval state or approve/reject
 * action buttons based on the state of a row in the policy violations table.
 */
const FormatPolicyViolationState = (
  state: Enums.QuotePolicyViolationState,
  { quoteID, quoteStatus, violation }: PolicyViolationAndQuoteStatus,
): JSX.Element => {
  const theme = useTheme();
  const { userInfo } = useContext(SlabContext);
  const violationMutation = useSlabMutation(
    'PUT quote policy violation state by quote ID and violation ID',
  );

  if (state === Enums.QuotePolicyViolationState.Pending) {
    if (
      quoteStatus.isApprovalRequest &&
      violation.quotePolicy.reviewers.some((r) => r.id === userInfo.user.id)
    ) {
      // state is Pending, and the current user can modify the state.

      const setViolationState = (newState: Enums.QuotePolicyViolationState): void => {
        violationMutation.mutate({
          args: {
            pathParams: {
              quoteID,
              violationID: violation.id,
            },
            body: newState,
          },
          schema: YupEnum(Enums.QuotePolicyViolationState, '').transform((val) =>
            JSON.stringify(val),
          ),
        });
      };

      return (
        <Box>
          <Button
            variant='pill'
            size='small'
            sx={{
              backgroundColor: theme.palette.SlabGreen['500'],
              border: `1px solid ${theme.palette.SlabGreen['500']}`, // add border to match dimensions of Reject button
              color: theme.palette.success.contrastText,
              ':hover': {
                backgroundColor: theme.palette.SlabGreen['600'],
                borderColor: theme.palette.SlabGreen['600'],
              },
            }}
            onClick={() => setViolationState(Enums.QuotePolicyViolationState.Approved)}
          >
            Approve
          </Button>{' '}
          <Button
            variant='pill'
            size='small'
            sx={{
              color: theme.palette.SlabRed['600'],
              border: `1px solid ${theme.palette.SlabRed['600']}`,
              fontWeight: 'normal',
              ':hover': {
                backgroundColor: theme.palette.SlabRed['200'],
              },
            }}
            onClick={() => setViolationState(Enums.QuotePolicyViolationState.Rejected)}
          >
            Reject
          </Button>
        </Box>
      );
    }

    // state is Pending, and the current user cannot modify the state.
    return (
      <Box color={theme.palette.SlabGray['600']}>
        [needs approval from {violation.quotePolicy.reviewerNamesDisplay()}]
      </Box>
    );
  }

  // state is Approved or Rejected.
  const textColor = ((): string => {
    if (state === Enums.QuotePolicyViolationState.Rejected) {
      return theme.palette.error.main;
    }
    return theme.palette.success.main;
  })();
  return <Box color={textColor}>{state}</Box>;
};

const FormatPolicyViolationThresholds = (thresholds: QuotePolicyThreshold[]): JSX.Element => (
  <div>
    {thresholds.map((t) => (
      <div key={`${t.kind}|${t.thresholdValue}`}>{t.description}</div>
    ))}
  </div>
);

export const QuoteDetailsPage = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const quoteID = params?.id ?? NIL_UUID;

  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);
  const isExternalIDEnabled = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagIntegrationExport,
  ]);

  const [initialSection, setInitialSection] = useState<QuoteSection | undefined>();
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [isSendQuoteOpen, setIsSendQuoteOpen] = useState(false);
  const [drawerSubmitType, setDrawerSubmitType] = useState<QuoteSubmitType>('save');
  const { toastHook: quoteToastHook, ...quoteDrawerProps } = useDrawerManager({
    baseUrl: '/quotes',
    resourceTypeName: 'quote',
    drawerProps: {
      resourceID: quoteID,
    },
  });
  const { toastHook: quoteStatusToastHook, ...quoteStatusDrawerProps } = useDrawerManager({
    baseUrl: '/quotes', // unused
    resourceTypeName: 'quote status',
    drawerProps: {
      resourceID: quoteID,
    },
  });

  const { userInfo } = useContext(SlabContext);

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET quote statuses', {});

  const quoteResult = useSlabQuery('GET quote by ID', {
    pathParams: {
      id: quoteID,
    },
  });
  const { isLoading: isLoadingQuote, isError: isErrorQuote, data: unfilledQuote } = quoteResult;

  const {
    isLoading: isLoadingQuoteProducts,
    isError: isErrorQuoteProducts,
    data: quoteProducts,
  } = useSlabQuery('GET quote products by quote ID', {
    pathParams: {
      id: quoteID,
    },
  });

  const policyNameColumn: ColumnConfig<
    PolicyViolationAndQuoteStatus,
    'violation.quotePolicy.name'
  > = {
    id: 'violation.quotePolicy.name',
    label: 'Policy Name',
    type: 'string',
    isDisplayed: true,
  };
  const policyThresholdsColumn: ColumnConfig<
    PolicyViolationAndQuoteStatus,
    'violation.quotePolicy.thresholds'
  > = {
    id: 'violation.quotePolicy.thresholds',
    label: 'Threshold',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: FormatPolicyViolationThresholds,
    formatValueToString: (thresholds: QuotePolicyThreshold[]): string =>
      thresholds.map((t) => t.description).join(', '),
  };
  const violationStateColumn: ColumnConfig<PolicyViolationAndQuoteStatus, 'violation.state'> = {
    id: 'violation.state',
    label: 'Approval',
    type: 'custom',
    isDisplayed: true,
    formatValueForWeb: FormatPolicyViolationState,
    formatValueToString: (
      state: Enums.QuotePolicyViolationState,
      { violation }: PolicyViolationAndQuoteStatus,
    ): string =>
      state === Enums.QuotePolicyViolationState.Pending
        ? `needs approval from ${violation.quotePolicy.reviewerNamesDisplay()}`
        : state,
  };
  const policyViolationsTableColumns: ColumnConfig<PolicyViolationAndQuoteStatus, any>[] = [
    policyNameColumn,
    policyThresholdsColumn,
    violationStateColumn,
  ];

  const quote = NewQuote({
    ...unfilledQuote,
    products: quoteProducts,
  });

  const policyViolationsResult = useSlabQuery('GET quote policy violations by quote ID', {
    pathParams: { quoteID },
  });
  const { isLoading: isLoadingPolicyViolations, isError: isErrorPolicyViolations } =
    policyViolationsResult;
  const policyViolationsCount = policyViolationsResult.data?.length ?? 0;
  const policyViolationsTableModel: TableDataModel<PolicyViolationAndQuoteStatus, { id: string }> =
    useLocalTableData<
      PolicyViolationAndQuoteStatus,
      { id: string },
      { violations?: QuotePolicyViolation[]; quote?: Quote }
    >({
      columns: policyViolationsTableColumns,
      rowData: MergeAsyncResults({ violations: policyViolationsResult, quote: quoteResult }),
      initialSortPath: 'violation.quotePolicy.name',
      transformRows: ({
        violations,
        quote: q,
      }: {
        violations?: QuotePolicyViolation[];
        quote?: Quote;
      }): PolicyViolationAndQuoteStatus[] => {
        if (q === undefined) {
          return [];
        }
        return (
          violations?.map((violation) => ({
            id: violation.id,
            quoteID: q.id,
            quoteStatus: q.status,
            violation,
          })) ?? []
        );
      },
    });

  const markAsSent = useSlabMutation('POST mark quote as sent');

  const isLoading =
    isLoadingQuote || isLoadingQuoteProducts || isLoadingStatuses || isLoadingPolicyViolations;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError =
    isErrorQuote || isErrorQuoteProducts || isErrorStatuses || isErrorPolicyViolations;
  const isDataUndefined =
    unfilledQuote === undefined || quoteProducts === undefined || statusList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const lowestPriorityInitialStatus = statusList.items
    .filter(
      (status) =>
        !ctx.userInfo.tenant.requiresQuoteApprovals || QuoteStatus.zero().canTransitionTo(status),
    )
    .reduce(
      (prev, curr) => (curr.priority < prev.priority ? curr : prev),
      NewQuoteStatus({ priority: Infinity }),
    );
  if (lowestPriorityInitialStatus.priority === Infinity) {
    LogRocket.error(
      `tenant ${ctx.userInfo.tenant.id} requires quote approvals but has no draft statuses`,
    );
  }

  const displayQuote = {
    ...quote,
    creationDateString: quote.creationDateDisplay(),
    revisionDateString: quote.revisionDateDisplay(),
    expirationDateString: quote.expirationDateDisplay(),
    bidDateString: quote.project.bidDateDisplay(),
    plantName: quote.project.plant.name,
    userFullName: quote.user.fullName(),
    projectName: quote.project.name,
    companyName: quote.company?.name ?? null,
    contactName: quote.contact?.fullName() ?? null,
    statusString: quote.status.name,
    dispatchCustomer: <DispatchCustomer quote={quote} />,
    ...quote.project.address,
  };

  const displayFields = {
    'Quote Information': (
      <DisplayField
        title='Quote Information'
        onEditClick={(): void => {
          setDrawerSubmitType('save');
          setInitialSection('Quote Information*');
          quoteDrawerProps.setIsOpen(true);
        }}
        value={displayQuote}
        displayMatrix={[
          [
            { key: 'creationDateString', type: 'default', label: 'Creation date' },
            { key: 'revisionDateString', type: 'default', label: 'Revision date' },
          ],
          [
            { key: 'expirationDateString', type: 'default', label: 'Expiration date' },
            { key: 'statusString', type: 'default', label: 'Status' },
          ],
          [
            { key: 'userFullName', type: 'default', label: 'Salesperson' },
            {
              key: 'projectName',
              label: 'Project',
              type: 'uri',
              uri: `/projects/${quote.project.id}`,
            },
          ],
          [
            { key: 'bidDateString', type: 'default', label: 'Bid date' },
            {
              label: 'Plant',
              key: 'plantName',
              type: 'uri',
              uri: `/plants/${quote.project.plant.id}`,
            },
          ],
        ]}
      />
    ),
    'Buyer Information': (
      <DisplayField
        title='Buyer Information'
        onEditClick={(): void => {
          setDrawerSubmitType('save');
          setInitialSection('Buyer Information');
          quoteDrawerProps.setIsOpen(true);
        }}
        value={displayQuote}
        displayMatrix={[
          [
            {
              key: 'companyName',
              label: 'Company',
              type: 'uri',
              uri: `/companies/${quote.company?.id}`,
            },
          ],
          [
            {
              key: 'contactName',
              label: 'Contact',
              type: 'uri',
              uri: `/contacts/${quote.contact?.id}`,
            },
          ],
          !usesDispatchCustomer
            ? []
            : [
                {
                  key: 'dispatchCustomer',
                  label: 'Dispatch customer',
                  type: 'default',
                },
              ],
        ]}
      />
    ),
    'Job Site Address': (
      <DisplayField
        title='Job Site Address'
        value={displayQuote}
        displayMatrix={[
          [
            { key: 'line1', type: 'default', label: 'Line 1' },
            { key: 'line2', type: 'default', label: 'Line 2' },
          ],
          [
            { key: 'postalCode', type: 'default', label: 'Zip/Postal' },
            { key: 'city', type: 'default', label: 'City/Town' },
          ],
          [
            { key: 'state', type: 'default', label: 'State/County' },
            { key: 'country', type: 'default', label: 'Country' },
          ],
        ]}
      />
    ),
    Terms: (
      <DisplayField
        title='Terms'
        onEditClick={(): void => {
          setDrawerSubmitType('save');
          setInitialSection('Terms');
          quoteDrawerProps.setIsOpen(true);
        }}
        value={displayQuote}
        displayMatrix={[[{ key: 'notes', type: 'notes', maxWidth: '100%', label: '' }]]}
      />
    ),
  };

  const QuoteDetailsContent = ((): JSX.Element => (
    <Box display='flex' flexDirection='column' gap='2rem'>
      <Box
        display='flex'
        paddingTop='2rem'
        gap='1.25rem'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        {displayFields['Quote Information']}
        {displayFields['Buyer Information']}
        {displayFields['Job Site Address']}
      </Box>
      {userInfo.tenant.requiresQuoteApprovals && policyViolationsCount > 0 && (
        <Box>
          <Box paddingBottom='1.5rem'>
            <Typography variant='h4'>Policy Violations</Typography>
          </Box>
          <DataTable tableName='quotes-policy-violations' tableModel={policyViolationsTableModel} />
        </Box>
      )}
      <Box>
        <MainProductTable
          quoteID={quote.id}
          onEditProducts={(): void => {
            setDrawerSubmitType('save');
            setInitialSection('Products');
            quoteDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
      <Box>
        <AdditionalProductTable
          quoteID={quote.id}
          onEditServices={(): void => {
            setDrawerSubmitType('save');
            setInitialSection('Additional services');
            quoteDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
      <Box>
        <PriceEscalationTable
          escalationsResult={{ ...quoteResult, data: quote.priceEscalations }}
          onEditEscalations={(): void => {
            setDrawerSubmitType('save');
            setInitialSection('Price Escalations');
            quoteDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
      <Box>{displayFields.Terms}</Box>
    </Box>
  ))();

  const title = quote.name !== null ? `${quote.name} (${quote.quoteNumber})` : quote.quoteNumber;
  const canEdit = CanEditCurrentRevision({
    userInfo: ctx.userInfo,
    savedQuoteStatus: quote.status,
  });
  const canSend =
    !ctx.userInfo.tenant.requiresQuoteApprovals ||
    quote.status.isApprovedToSend ||
    quote.status.isSent;

  const [displayApprovalWorkflowChip, approvalWorkflowChipText, approvalWorkflowChipColor] = ((
    q: Quote,
  ): [boolean, string, ChipOwnProps['color']] => {
    if (!userInfo.tenant.requiresQuoteApprovals) {
      return [false, '', 'default'];
    }

    let displayChip = false;
    let text = '';
    let color: ChipOwnProps['color'] = 'default';

    if (q.status.isApprovalRequest) {
      displayChip = true;
      text = 'Awaiting Approval';
      color = 'info';
    } else if (q.status.isApprovedToSend || q.status.isSent) {
      displayChip = true;
      text = 'Approved';
      color = 'secondary';
    } else if (q.status.isRejected) {
      displayChip = true;
      text = 'Rejected';
      color = 'warning';
    }

    return [displayChip, text, color];
  })(quote);
  const convertedExternalID = convertExternalID(quote.externalID);

  return (
    <Page title={title}>
      {quoteToastHook.toast}
      {quoteStatusToastHook.toast}
      <QuotePdfModal
        quote={quote}
        isOpen={isPdfModalOpen}
        setIsOpen={setIsPdfModalOpen}
        requiresQuoteApprovals={ctx.userInfo.tenant.requiresQuoteApprovals}
      />
      <QuoteDrawer
        {...quoteDrawerProps}
        initialSection={initialSection}
        submitType={drawerSubmitType}
        makeDefaultFormikQuote={makeDefaultFormikQuote}
        onRequestSuccess={(): void => {
          quoteToastHook.showToast('success');
        }}
        onNylasSuccess={() => {
          quoteToastHook.showToast('success', 'The quote was sent successfully');
        }}
        onRequestError={(): void => {
          quoteToastHook.showToast('error');
        }}
        onNylasError={() => {
          quoteToastHook.showToast('error', 'Failed to send the quote');
        }}
        onClose={(): void => {
          setDrawerSubmitType('save');
          setInitialSection(undefined);
        }}
      />
      <QuoteStatusDrawer {...quoteStatusDrawerProps} />
      <NylasFlyout
        isOpen={isSendQuoteOpen}
        setIsOpen={setIsSendQuoteOpen}
        quoteID={quote.id}
        key={quote.id}
        onClose={(): void => {
          setIsSendQuoteOpen(false);
        }}
        onSuccess={async (): Promise<void> => {
          await MarkAsSentAndShowToast({
            quoteID: quote.id,
            markAsSentMutation: markAsSent,
            toastHook: quoteToastHook,
          });
        }}
        onError={(err): void =>
          quoteToastHook.showToast('error', ServerErrorMessage(err) ?? 'Unable to send quote')
        }
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem' gap='1.25rem'>
        <Box display='flex' alignItems='center' gap='1.25rem'>
          <Typography variant='h1'>{title}</Typography>
          <Chip label={`Revision ${quote.revisionNumber}`} />
          {quote.hasUOMIssues() && <Chip color='error' label='UOM issue' />}
          {isExternalIDEnabled && (
            <Chip
              color='success'
              label={`External ID: ${
                convertedExternalID === null ? '[not set]' : convertedExternalID
              }`}
            />
          )}
          {displayApprovalWorkflowChip && (
            <Chip color={approvalWorkflowChipColor} label={approvalWorkflowChipText} />
          )}
        </Box>
        <Box display='flex' gap='1rem'>
          {canSend ? (
            <ButtonPill
              text='Send Quote'
              variant='secondary'
              onClick={(): void => setIsSendQuoteOpen(true)}
            />
          ) : (
            <Tooltip arrow placement='bottom' title='Approval required before sending'>
              <Box display='flex'>
                <ButtonPill text='send quote' variant='secondary' disabled onClick={() => {}} />
              </Box>
            </Tooltip>
          )}
          <ButtonPill
            text='Generate Quote'
            variant='secondary'
            onClick={(): void => setIsPdfModalOpen(true)}
          />
          <ButtonMenu
            variant='primary'
            label='Edit Quote'
            icon='edit'
            options={[
              canEdit
                ? {
                    label: 'Edit Quote',
                    icon: 'edit',
                    onClick: (): void => {
                      setDrawerSubmitType('save');
                      setInitialSection('Quote Information*');
                      quoteDrawerProps.setIsOpen(true);
                    },
                  }
                : {
                    label: 'Edit Quote Status',
                    icon: 'edit',
                    onClick: (): void => {
                      quoteStatusDrawerProps.setIsOpen(true);
                    },
                  },
              {
                label: 'New Revision',
                icon: 'add',
                onClick: (): void => {
                  setDrawerSubmitType('saveAsNewRevision');
                  setInitialSection('Quote Information*');
                  quoteDrawerProps.setIsOpen(true);
                },
              },
              {
                label: 'Duplicate Quote',
                icon: 'copy',
                onClick: (): void => {
                  setDrawerSubmitType('saveAsNewQuote');
                  setInitialSection('Quote Information*');
                  quoteDrawerProps.setIsOpen(true);
                },
              },
            ]}
          />
        </Box>
      </Box>
      {quote.hasUOMIssues() && (
        <Typography color={theme.palette.error.main}>
          The products in this quote contain UOM issues
        </Typography>
      )}
      {QuoteDetailsContent}
    </Page>
  );
};
