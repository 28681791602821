import { Box } from '@mui/material';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { NylasFlyoutPage } from '../../components/Nylas/NylasFlyout';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import Enums from '../../generated-types/Enums';
import { Product } from '../../generated-types/Product/Product';
import { Quote } from '../../generated-types/Quote/Quote';
import { QuotePolicy } from '../../generated-types/QuotePolicy/QuotePolicy';
import { QuoteStatus } from '../../generated-types/QuoteStatus/QuoteStatus';
import { UserInfo } from '../../generated-types/UserInfo/UserInfo';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQueryFetcher } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { ArrayToIndexedRecord } from '../../utils/DomainHelpers';
import { QueryError } from '../../utils/Query';
import { isValidUUID, NIL_UUID } from '../../utils/UUID';
import { QuoteDrawerApprovedPage } from './components/QuoteDrawerApprovedPage';
import { QuoteDrawerMainPage } from './components/QuoteDrawerMainPage';
import { QuoteSection } from './components/QuoteDrawerSections';
import { QuoteDrawerState } from './components/QuoteDrawerUtilities';
import { QuoteDrawerViolationsPage } from './components/QuoteDrawerViolationsPage';
import {
  FormikQuote,
  FormikQuoteStatus,
  NewQuoteFromFormik,
  QuoteFormikType,
  QuoteSchemaWire,
  QuoteSubmitType,
} from './QuoteFormik';

export type QuoteDrawerProps = {
  resourceID: string | null;
  submitType: QuoteSubmitType;
  makeDefaultFormikQuote: (data: {
    userInfo: UserInfo;
    submitType: QuoteSubmitType;
  }) => QuoteFormikType;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRequestSuccess: (quote: Quote) => void;
  onNylasSuccess: (quoteID: string) => void;
  onRequestError: (error: QueryError) => void;
  onNylasError: (error: QueryError) => void;
  onClose?: () => void;

  /** @default 'Quote Information' */
  initialSection?: QuoteSection;
};

export const QuoteDrawer = ({
  resourceID,
  submitType,
  makeDefaultFormikQuote,
  initialSection,
  onRequestError,
  onNylasError,
  onRequestSuccess,
  onNylasSuccess,
  isOpen,
  setIsOpen,
  onClose,
}: QuoteDrawerProps): JSX.Element => {
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);
  const { requiresQuoteApprovals } = ctx.userInfo.tenant;
  const navigate = useNavigate();

  const [drawerState, setDrawerState] = useState<QuoteDrawerState>('quoteLoading');
  const [editedQuote, setEditedQuote] = useState(
    makeDefaultFormikQuote({ userInfo: ctx.userInfo, submitType }),
  );
  // We prefer `useRef` here because it allows for immediate mutable changes to the
  // state. If we use `useState` we see "delays" between setting values vs. rendering,
  // which causes form-values to fall out-of-sync with state-values.
  const curProductsRef = useRef<Record<number, Product | null>>({});
  const [policyViolations, setPolicyViolations] = useState<QuotePolicy[]>([]);

  const quoteFetcher = useSlabQueryFetcher<
    'GET quote by ID',
    QueryRouteBarrelTypes['GET quote by ID'],
    QueryError
  >();
  const statusesFetcher = useSlabQueryFetcher<
    'GET quote statuses',
    QueryRouteBarrelTypes['GET quote statuses'],
    QueryError
  >();
  const projectProductsFetcher = useSlabQueryFetcher<
    'GET project products by project ID',
    QueryRouteBarrelTypes['GET project products by project ID'],
    QueryError
  >();

  const createNew = useSlabMutation('POST quote', {
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });
  const updateExisting = useSlabMutation('PUT quote by ID', {
    onSuccess: onRequestSuccess,
    onError: onRequestError,
  });
  const findViolations = useSlabMutation('POST check quote for policy violations');

  // Callback functions to handle each state machine transition that might be
  // triggered while the drawer is open.

  const closeDrawer = (): void => {
    setIsOpen(false);
    setDrawerState('quoteLoading');
    setEditedQuote(makeDefaultFormikQuote({ userInfo: ctx.userInfo, submitType }));
    curProductsRef.current = {};
    onClose?.();
  };

  const redirectToQuote = (quoteID: string): void => {
    navigate(`/quotes/${quoteID}`);
  };

  const closeDrawerAndRedirectIfNewQuote = (quoteID: string): void => {
    closeDrawer();
    if (submitType !== 'save') {
      redirectToQuote(quoteID);
    }
  };

  const setEditedQuoteAfterUpsert = (formData: QuoteFormikType, apiData: Quote): void => {
    setEditedQuote({
      ...formData,
      // Persist the server's version of values that might be changed by the
      // server, and retain the user's original data for all other properties.
      id: apiData.id,
      quoteNumber: apiData.quoteNumber,
      revisionNumber: apiData.revisionNumber,
      status: FormikQuoteStatus(apiData.status),
    });
  };

  // Callers of upsertQuote must setEditedQuote as appropriate.
  const upsertQuote = async (values: QuoteFormikType): Promise<Quote> => {
    const request = NewQuoteFromFormik(values);
    let response: Quote;
    if (request.id === NIL_UUID) {
      response = await createNew.mutateAsync({
        args: {
          body: request,
        },
        schema: QuoteSchemaWire,
      });
    } else {
      response = await updateExisting.mutateAsync({
        args: {
          pathParams: { id: request.id },
          body: request,
        },
        schema: QuoteSchemaWire,
      });
    }

    curProductsRef.current = ArrayToIndexedRecord(response.products, (qp) => qp.product);
    return response;
  };

  const upsertQuoteWithoutApprovalsEnabled = async (values: QuoteFormikType): Promise<void> => {
    try {
      const savedQuote = await upsertQuote(values);
      // Don't need to setEditedQuote or curProductsRef because closeDrawer will clear that state.
      closeDrawer();
      if (submitType !== 'save') {
        redirectToQuote(savedQuote.id);
      }
    } catch {
      setDrawerState('error');
    }
  };

  const upsertQuoteAsApprovalRequest = async (): Promise<Quote> => {
    try {
      const statusList = await statusesFetcher('GET quote statuses', {});
      const firstApprovalRequestStatus = statusList.items
        .sort((a, b) => a.priority - b.priority)
        .find((s) => s.isApprovalRequest);

      const values = { ...editedQuote, status: FormikQuoteStatus(firstApprovalRequestStatus) };
      const savedQuote = await upsertQuote(values);
      setEditedQuoteAfterUpsert(values, savedQuote);
      return savedQuote;
    } catch (e) {
      setDrawerState('error');
      throw e;
    }
  };

  const checkQuoteForViolations = async (values: QuoteFormikType): Promise<void> => {
    setEditedQuote(values);

    try {
      const violations = await findViolations.mutateAsync({
        args: { body: NewQuoteFromFormik(values) },
        schema: QuoteSchemaWire,
      });
      setPolicyViolations(violations.violatedPolicies);
      if (violations.violatedPolicies.length > 0) {
        setDrawerState('reviewViolations');
      } else {
        const savedQuote = await upsertQuote(values);
        setEditedQuoteAfterUpsert(values, savedQuote);
        setDrawerState('confirmApproved');
      }
    } catch {
      setDrawerState('error');
    }
  };

  // This useEffect handles initial state management for curProductsRef: when
  // the drawer opens, we initialize that reference to the Products in the
  // initial quote data (if any); when the drawer closes, we empty out that
  // reference to avoid memory leaks.
  useEffect(() => {
    // If the drawer should be closed, make sure it is.
    if (!isOpen) {
      if (drawerState !== 'quoteLoading') {
        closeDrawer();
        // If we ever implement sc-144, we will maybe need to redirect to the
        // editedQuote's detail page here if the drawerState and submitType
        // indicate that a new Quote object has been created in this drawer.
      }
      return;
    }

    // If this is a new quote, the initial data is Quote.zero().
    if (!isValidUUID(resourceID)) {
      const initialQuote = makeDefaultFormikQuote({ userInfo: ctx.userInfo, submitType });
      setEditedQuote(initialQuote);

      // If this new quote has a default project, load its product information.
      // This is important to have cost information available to the form's calculations.
      if (isValidUUID(initialQuote.project.id)) {
        projectProductsFetcher('GET project products by project ID', {
          pathParams: { id: initialQuote.project.id ?? '' },
        })
          .then((projectProducts) => {
            curProductsRef.current = ArrayToIndexedRecord(projectProducts, (pp) => pp.product);
            setDrawerState('edit');
          })
          .catch(() => {
            setDrawerState('error');
          });
        return;
      }

      // If the default new quote data does not reference a project, it has no products.
      curProductsRef.current = {};
      setDrawerState('edit');
      return;
    }

    // If this is based on an existing quote ('save' for existing quote, or
    // 'saveAsNewRevision' / 'saveAsNewQuote'), load initial data from our API.
    setDrawerState('quoteLoading');
    quoteFetcher('GET quote by ID', {
      pathParams: {
        id: resourceID ?? '',
      },
    })
      .then(async (quote) => {
        const formikValues = FormikQuote(quote, usesDispatchCustomer, submitType);
        if (submitType === 'save') {
          setEditedQuote(formikValues);
        } else {
          const allStatuses = await statusesFetcher('GET quote statuses', {});
          const firstDraftStatus =
            sortBy(allStatuses.items, (s) => s.priority).find((s) => s.isDraft) ??
            QuoteStatus.zero();
          const currentDate = DateTime.now().toISODate();

          // If there were previously a revision date set, default to today's date.
          const revisionDate =
            formikValues.revisionDate !== null && formikValues.revisionDate !== ''
              ? currentDate
              : formikValues.revisionDate;

          setEditedQuote({
            ...formikValues,
            id: NIL_UUID,
            status: FormikQuoteStatus(firstDraftStatus),
            creationDate: currentDate,
            revisionDate,
          });
        }
        curProductsRef.current = ArrayToIndexedRecord(quote.products, (qp) => qp.product);

        setDrawerState('edit');
      })
      .catch(() => {
        setDrawerState('error');
      });
  }, [isOpen, resourceID, submitType]);

  return (
    <SlabDrawer
      isOpen={isOpen}
      // Required in the scenario a user closes then reopens the same drawer. Product state
      // will not be updated due to single-hook rendering values.
      slideProps={{
        unmountOnExit: true,
      }}
      paperProps={{
        sx: {
          width: '80rem',
          maxWidth: '100%',
        },
      }}
    >
      {drawerState === 'quoteLoading' && <LoadingSpinner />}
      {drawerState === 'edit' && (
        <QuoteDrawerMainPage
          formikQuote={editedQuote}
          curProductsRef={curProductsRef}
          submitForm={
            requiresQuoteApprovals ? checkQuoteForViolations : upsertQuoteWithoutApprovalsEnabled
          }
          cancelForm={closeDrawer}
          submitType={submitType}
          isSubmitPending={createNew.isPending || updateExisting.isPending}
          initialSection={initialSection}
        />
      )}
      {drawerState === 'violationsLoading' && <LoadingSpinner />}
      {drawerState === 'reviewViolations' && (
        <QuoteDrawerViolationsPage
          violatedPolicies={policyViolations}
          goBackToForm={() => {
            setDrawerState('edit');
          }}
          submitForm={async () => {
            try {
              const savedQuote = await upsertQuote(editedQuote);
              closeDrawerAndRedirectIfNewQuote(savedQuote.id);
            } catch {
              setDrawerState('error');
            }
          }}
          submitForReview={async () => {
            try {
              const savedQuote = await upsertQuoteAsApprovalRequest();
              closeDrawerAndRedirectIfNewQuote(savedQuote.id);
            } catch {
              setDrawerState('error');
            }
          }}
        />
      )}
      {drawerState === 'confirmApproved' && (
        <QuoteDrawerApprovedPage
          closeDrawer={() => {
            closeDrawerAndRedirectIfNewQuote(editedQuote.id);
          }}
          sendQuote={async () => {
            try {
              const savedQuote = await upsertQuoteAsApprovalRequest();
              if (!savedQuote.status.isApprovedToSend) {
                closeDrawerAndRedirectIfNewQuote(savedQuote.id);
                return;
              }
              setDrawerState('send');
            } catch {
              setDrawerState('error');
            }
          }}
        />
      )}
      {drawerState === 'send' && (
        <NylasFlyoutPage
          quoteID={editedQuote.id}
          setIsOpen={setIsOpen}
          onSuccess={() => {
            closeDrawerAndRedirectIfNewQuote(editedQuote.id);
            onNylasSuccess(editedQuote.id);
          }}
          onError={onNylasError}
        />
      )}
      {drawerState === 'error' && (
        <Box padding='5rem 3.5rem'>
          <p>ERROR</p>
          <ButtonPill variant='primary' text='Close' onClick={closeDrawer} />
        </Box>
      )}
    </SlabDrawer>
  );
};
