import { Box } from '@mui/material';
import { useContext } from 'react';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import {
  FormatErrorCell,
  FormatLinkCell,
  FormatNotSetCell,
} from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Currency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { ProjectProduct } from '../../../generated-types/ProjectProduct/ProjectProduct';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { formatAsPercent } from '../../../utils/DomainHelpers';
import { convertExternalID } from '../../../utils/ExternalIDHelpers';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

type ProjectProductListRow = {
  order: number;
  id: string;
  name: string;
  alternateID: string;
  quantity: number;
  usage: string | null;
  unitVolume: number | null;
  unitCost: Dinero.Dinero;
  unitPrice: Currency;
  unitMargin: number;
  suggestedPrice: Currency | null;
  incompatibleMixBatchUnits: string;
  externalID: string | null;
};

const buildTableDataModelConfig = ({
  productsResult,
  isExternalIDEnabled,
}: {
  productsResult: PossiblyAsyncResult<ProjectProduct[] | undefined>;
  isExternalIDEnabled: boolean;
}): InitialTableData<ProjectProductListRow, { id: string }, ProjectProduct[] | undefined> => {
  const transformRows = (products: ProjectProduct[] = []): ProjectProductListRow[] =>
    products.map(
      (p, idx): ProjectProductListRow => ({
        // 'order' uses index assuming that the products are already sorted by order.
        order: idx + 1,

        id: p.product.id,
        name: p.product.name,
        alternateID: p.product.alternateID,
        quantity: p.quantity,
        usage: p.usage,
        unitVolume: p.product.cuydVolume,
        unitCost: p.costPerUnit(),
        unitPrice: p.price,
        unitMargin: p.unitMargin(),
        suggestedPrice: p.calculateSuggestedPrice(),
        incompatibleMixBatchUnits: p.product.incompatibleMixBatchUnits ? 'UOM issue' : '',
        externalID: convertExternalID(p.product.externalID),
      }),
    );

  const columns: ColumnConfig<ProjectProductListRow, NestedKeyOf<ProjectProductListRow>>[] = [
    {
      id: 'order',
      label: 'Order',
      type: 'number',
      isDisplayed: false,
    },
    {
      id: 'alternateID',
      label: 'Product ID',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/products/${row.id}` }),
    },
    {
      id: 'quantity',
      label: 'Quantity',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: 'usage',
      label: 'Usage',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'unitVolume',
      label: 'Unit volume',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: 'unitCost',
      label: 'Unit cost',
      type: 'Dinero',
      isDisplayed: true,
    },
    {
      id: 'unitPrice',
      label: 'Unit price',
      type: 'Currency',
      isDisplayed: true,
    },
    {
      id: 'unitMargin',
      label: 'Unit margin',
      type: 'number',
      isDisplayed: true,
      formatValueToString: formatAsPercent,
    },
    {
      id: 'incompatibleMixBatchUnits',
      label: 'Incompatible batch unit',
      tooltipText:
        '"UOM issue" products include a mix material whose batch unit is incompatible with its underlying material’s cost unit.',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatErrorCell(v),
    },
    {
      id: 'suggestedPrice',
      label: 'Suggested price',
      type: 'Currency',
      isDisplayed: false,
    },
  ];

  if (isExternalIDEnabled) {
    const externalIDColumn: ColumnConfig<
      ProjectProductListRow,
      NestedKeyOf<ProjectProductListRow>
    > = {
      id: 'externalID',
      label: 'External ID',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatNotSetCell(v),
    };

    const nameIndex = columns.findIndex((column) => column.id === 'name');

    columns.splice(nameIndex + 1, 0, externalIDColumn);
  }

  return {
    rowData: productsResult,
    transformRows,
    columns,
    initialSortPath: 'order',
  };
};

type ProductTableProps = {
  projectID: string;
  onEditProducts: () => void;
};

export const ProductTable = ({ projectID, onEditProducts }: ProductTableProps): JSX.Element => {
  const ctx = useContext(SlabContext);
  const isExternalIDEnabled = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagIntegrationExport,
  ]);

  const productsResult = useSlabQuery('GET project products by project ID', {
    pathParams: {
      id: projectID,
    },
  });
  const { isLoading, isError, data: products } = productsResult;

  const tableModel = useLocalTableData(
    buildTableDataModelConfig({ productsResult, isExternalIDEnabled }),
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || products === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box padding='1rem'>
      <Box height='400px'>
        <Box display='flex' justifyContent='flex-end' paddingBottom='1.5rem'>
          <ButtonPill
            text='edit products'
            variant='primary'
            size='small'
            onClick={onEditProducts}
            icon='edit'
          />
        </Box>
        <DataTable tableName='project-products' tableModel={tableModel} />
      </Box>
    </Box>
  );
};
