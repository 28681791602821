// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Address, NewAddress } from '../Address/Address';

export class Market {
  readonly id: string;
  readonly name: string;
  readonly address: Address | null;
  readonly phoneNumber: string | null;
  readonly phoneNumber2: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.address =
      // eslint-disable-next-line no-nested-ternary
      (data.address ?? null) === null ? null : NewAddress(data.address);
    this.phoneNumber = data.phoneNumber === undefined ? null : data.phoneNumber;
    this.phoneNumber2 = data.phoneNumber2 === undefined ? null : data.phoneNumber2;
  }

  static zero(): Market {
    const zeroValues: ExcludeMethodsDeep<Market> = {
      id: NIL_UUID,
      name: '',
      address: null,
      phoneNumber: null,
      phoneNumber2: null,
    };
    return new Market(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMarket = (props: PartialDeep<Market, { recurseIntoArrays: true }>): Market =>
  new Market(props);

export const NewMarketFromDomainObject = (
  props: PartialDeep<DomainObject<Market>, { recurseIntoArrays: true }>,
): Market => new Market(props);
