import { Box, Button, Divider, Grid, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';

import { Input } from '../../../components/Input/Input';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import Enums from '../../../generated-types/Enums';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { NullableCurrencyString } from '../../../utils/Currency';
import { lookups } from '../../../utils/DomainHelpers';
import { SetFormikValue } from '../../../utils/FormikHelpers';
import { NIL_UUID } from '../../../utils/UUID';
import { QuoteConfigFormikType, QuoteConfigProductFormikType } from '../QuoteConfigFormik';

export const NewEmptyQuoteConfigProduct = (): QuoteConfigProductFormikType => ({
  product: {
    id: null,
    option: null,
  },
  usage: null,
  displayOnly: null,
});

export const DynamicQuoteConfigProductLookup = (): JSX.Element => {
  const theme = useTheme();

  const formikBag = useFormikContext<QuoteConfigFormikType>();

  const {
    isLoading: isLoadingProductCategoryAggs,
    isError: isErrorProductCategoryAggs,
    data: productCategoryAggs,
  } = useSlabQuery(
    'GET product category aggregations',
    {
      queryParams: {
        filterBy: [
          {
            name: 'plantId',
            operation: Enums.FilterOperation.Equals,
            value: formikBag.values.plant.id ?? '',
          },
        ],
      },
    },
    {
      enabled: formikBag.values.plant.id !== null,
    },
  );

  if (formikBag.values.plant.id === null) {
    return <div>Please select a plant</div>;
  }

  if (isLoadingProductCategoryAggs) {
    return <LoadingSpinner />;
  }

  if (isErrorProductCategoryAggs || productCategoryAggs === undefined) {
    return <div>Error</div>;
  }

  const totalPossibleProducts = productCategoryAggs.reduce((acc, pcAgg) => acc + pcAgg.count, 0);

  const formikQuoteConfigProducts = formikBag.values.products;

  return (
    <FieldArray
      name='products'
      render={(arrayHelpers): JSX.Element => {
        const isAddDisabled = ((): boolean => {
          const noOptionsLeft = totalPossibleProducts <= formikBag.values.products.length;
          const someProductNull = formikBag.values.products.some((p) => p.product.id === null);

          return noOptionsLeft || someProductNull;
        })();
        return (
          <>
            {formikQuoteConfigProducts.map((fqcp, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={`quote-config-product-products-grid-${fqcp.product.id ?? NIL_UUID}-${index}`}
                display='flex'
                flexDirection='column'
                gap='0.5rem'
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <ApiLookupInput
                          formState={formikBag.values}
                          name={`products.${index}.product`}
                          label='Product*'
                          route={{
                            barrel: 'GET products',
                            args: (inputText): QueryRouteBarrelTypes['GET products']['args'] => {
                              const args = ConstructListQueryParams(inputText);
                              return {
                                ...args,
                                queryParams: {
                                  ...args.queryParams,
                                  perPage: args.queryParams.perPage + 40,
                                  filterBy: [
                                    ...(args.queryParams.filterBy ?? []),
                                    {
                                      operation: Enums.FilterOperation.Equals,
                                      name: 'plantId',
                                      value: formikBag.values.plant.id ?? '',
                                    },
                                  ],
                                },
                              };
                            },
                            options: {
                              enabled: formikBag.values.plant.id !== null,
                            },
                            transform: (pl): LookupInputOption[] => {
                              const currentIDs = formikBag.values.products.map(
                                (p) => p.product.id ?? '',
                              );
                              const curSelectedProducts = pl.items.filter((p) =>
                                currentIDs.includes(p.id),
                              );
                              const filteredProducts = pl.items
                                .filter((p) => !currentIDs.includes(p.id))
                                .slice(0, DEFAULT_LOOKUP_LENGTH);
                              return lookups({
                                items: filteredProducts,
                                label: (m) => m.name,
                                sublabels: (p) => [p.alternateID],
                                // The count of selectable items excluding currently selected products.
                                count: pl.count - curSelectedProducts.length,
                              });
                            },
                          }}
                          onMatchChange={(productID, pl): void => {
                            if (productID === null) {
                              SetFormikValue(
                                formikBag,
                                `products.${index}`,
                                NewEmptyQuoteConfigProduct(),
                              );
                            } else {
                              const matchingProduct = (pl?.items ?? []).find(
                                (p) => p.id === productID,
                              );
                              SetFormikValue(
                                formikBag,
                                `products.${index}.displayOnly.listPrice`,
                                NullableCurrencyString({
                                  cur: matchingProduct?.listPrice ?? null,
                                }),
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Input
                          name={`products.${index}.usage`}
                          label='Description'
                          type='textarea'
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Input
                          name={`products.${index}.displayOnly.listPrice`}
                          label='List price'
                          type='text'
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    xs={1}
                    pt='0px !important'
                  >
                    <Button
                      onClick={(): void => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      <Trash variant='TwoTone' color={theme.palette.SlabIndigo['700']} />
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))}
            <Button
              disabled={isAddDisabled}
              onClick={(): void => {
                arrayHelpers.push(NewEmptyQuoteConfigProduct());
              }}
              sx={{
                textTransform: 'none',
                color: theme.palette.SlabIndigo['700'],
              }}
            >
              <Box display='flex' gap='0.5rem'>
                <Add color={theme.palette.SlabIndigo['700']} />
                Add {formikQuoteConfigProducts.length === 0 ? 'product' : 'another'}
              </Box>
            </Button>
          </>
        );
      }}
    />
  );
};
