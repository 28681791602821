import { Box, Divider, Grid, Typography } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';

import { Activity, NewActivity } from '../../generated-types/Activity/Activity';
import Enums from '../../generated-types/Enums';
import { ActivityCategory, ActivityStatus } from '../../generated-types/generated-enums';
import { ProjectSummary } from '../../generated-types/ProjectSummary/ProjectSummary';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { ListURLParams, QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { FormatDateTimeLocal } from '../../utils/DateHelpers';
import { DefaultEnsureDefined, isValidReference, lookups } from '../../utils/DomainHelpers';
import { FormErrorNotification, SetFormikValue } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { ButtonPill } from '../ButtonPill/ButtonPill';
import { Input } from '../Input/Input';
import { InputDropdown, InputOption } from '../InputDropdown/InputDropdown';
import { InputMultiLookup, ReferenceValueMatcher } from '../InputMultiLookup/InputMultiLookup';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../LookupInput/ApiLookupInput';
import { ErrorOption, LoadingOption } from '../LookupInput/ApiLookupInputBase';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../LookupInput/LookupInputSharedComponents';
import { SlabDrawer } from '../SlabDrawer/SlabDrawer';
import { ActivityFormikType, ActivitySchema, FormikActivity } from './ActivityFormik';
import { contactsForCompanies } from './ActivityTagHelpers';

const categoryOpts: InputOption[] = Object.values(ActivityCategory).map((s: string) => ({
  label: s,
  value: s,
}));
const statusOpts: InputOption[] = Object.values(ActivityStatus).map((s: string) => ({
  label: s,
  value: s,
}));

type ActivityDrawerProps = Omit<SharedDrawerOverrideProps<Activity>, 'resourceID' | 'onSave'> & {
  activityID: string | null;
  currentDate: DateTime;
  onSuccess: (activity: Activity) => void;
};

type ActivityDrawerContentProps = Pick<ActivityDrawerProps, 'setIsOpen'> & {
  isCreating: boolean;
  isUpdating: boolean;
};

const ActivityDrawerContent = ({
  isCreating,
  isUpdating,
  setIsOpen,
}: ActivityDrawerContentProps): JSX.Element => {
  const formikBag = useFormikContext<ActivityFormikType>();

  const isNew = formikBag.values.id === NIL_UUID;
  const formikCompanyIDs = formikBag.values.companies
    .map((c) => c.id ?? '')
    .filter((c) => c !== '');

  const {
    isLoading: isLoadingProjectCompanies,
    isError: isErrorProjectCompanies,
    data: projectCompanies,
  } = useSlabQuery(
    'GET project companies by project ID',
    {
      pathParams: {
        id: formikBag.values.project?.id ?? '',
      },
    },
    {
      enabled: formikBag.values.project !== null && formikBag.values.project.id !== null,
    },
  );

  const {
    isLoading: isLoadingCompanyContactList,
    isError: isErrorCompanyContactList,
    data: companyContactList,
  } = useSlabQuery(
    'GET contacts by company IDs',
    {
      queryParams: {
        id: formikCompanyIDs,
      },
    },
    {
      enabled: formikCompanyIDs.length > 0,
    },
  );

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: userList,
  } = useSlabQuery('GET users', {});

  const loading = isLoadingUsers;

  if (loading) {
    return <LoadingSpinner />;
  }

  const error = isErrorUsers;

  const dataUndefined = userList === undefined;

  if (error || dataUndefined) {
    return <div>ERROR</div>;
  }

  const companyLookups = ((): InputOption[] => {
    if (isLoadingProjectCompanies) {
      return [LoadingOption];
    }
    if (isErrorProjectCompanies) {
      return [ErrorOption];
    }
    return lookups({
      items: (projectCompanies ?? []).map((pc) => pc.company),
      label: (c) => c.name,
      count: (projectCompanies ?? []).length,
    });
  })();

  const contactLookups = ((): InputOption[] => {
    if (isLoadingCompanyContactList) {
      return [LoadingOption];
    }
    if (isErrorCompanyContactList) {
      return [ErrorOption];
    }
    return lookups({
      items: (companyContactList?.items ?? []).filter((cc) =>
        formikCompanyIDs.includes(cc.company.id),
      ),
      label: (c) => c.fullName(),
      count: companyContactList?.count ?? 0,
    });
  })();

  const userLookups = lookups({
    items: userList.items.filter((u) => u.isSelectable()),
    count: userList.items.filter((u) => u.isSelectable()).length,
    label: (c) => c.fullName(),
  });

  const companyDisabled =
    !isValidReference(formikBag.values.project) && formikBag.values.companies.length === 0;
  const contactDisabled =
    formikBag.values.companies.length === 0 && formikBag.values.contacts.length === 0;

  return (
    <Box padding='5rem 3.5rem 5rem 3.5rem'>
      <Typography variant='h1' fontSize='2rem'>
        {isNew ? 'Add ' : 'Edit '}
        activity
      </Typography>
      <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input name='name' label='Name*' />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input name='description' label='Description' type='textarea' />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              name='startTime'
              label='Start Time*'
              type='datetime-local'
              onInputChange={async (val): Promise<void> => {
                const start = DateTime.fromISO(val);
                const end = DateTime.fromISO(formikBag.values.endTime);

                // If start is later than end, set end to start + 30 mins.
                if (start >= end) {
                  SetFormikValue(
                    formikBag,
                    'endTime',
                    FormatDateTimeLocal(start.plus({ minutes: 30 })),
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name='endTime'
              label='End Time*'
              type='datetime-local'
              onInputChange={async (val): Promise<void> => {
                const start = DateTime.fromISO(formikBag.values.startTime);
                const end = DateTime.fromISO(val);

                // If start is later than end, set start to end - 30 mins.
                if (start >= end) {
                  SetFormikValue(
                    formikBag,
                    'startTime',
                    FormatDateTimeLocal(end.minus({ minutes: 30 })),
                  );
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputDropdown label='Category' name='category' options={categoryOpts} />
          </Grid>
          <Grid item xs={6}>
            <InputDropdown label='Status' name='status' options={statusOpts} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputMultiLookup
              name='assignees'
              label='Assignees'
              options={userLookups}
              onMatchChange={(matches): void => {
                SetFormikValue(
                  formikBag,
                  'assignees',
                  matches.map(({ value, label }) => ({
                    id: value,
                    option: {
                      value,
                      label,
                    },
                  })),
                );
              }}
              formValueMatcher={ReferenceValueMatcher}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ApiLookupInput
              formState={formikBag.values}
              name='project'
              label='Project'
              route={{
                barrel: 'GET project summaries',
                args: (inputText): QueryRouteBarrelTypes['GET project summaries']['args'] => {
                  const args = ConstructListQueryParams(inputText);
                  const filterBy: ListURLParams<ProjectSummary>['filterBy'] = [
                    ...(args.queryParams?.filterBy ?? []),
                    {
                      name: 'isArchived',
                      operation: Enums.FilterOperation.Equals,
                      value: false,
                    },
                  ];
                  return {
                    ...args,
                    queryParams: {
                      ...args.queryParams,
                      filterBy,
                    },
                  };
                },
                transform: (pl): LookupInputOption[] =>
                  lookups({
                    ...pl,
                    label: (p) => p.name,
                  }),
              }}
              onMatchChange={(projectID): void => {
                if (projectID === null) {
                  SetFormikValue(formikBag, 'companies', []);
                  SetFormikValue(formikBag, 'contacts', []);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputMultiLookup
              name='companies'
              label={companyDisabled ? 'Select a project' : 'Companies'}
              disabled={companyDisabled}
              options={companyLookups}
              onMatchChange={(matches): void => {
                SetFormikValue(
                  formikBag,
                  'companies',
                  matches.map(({ value, label }) => ({
                    id: value,
                    option: {
                      value,
                      label,
                    },
                  })),
                );
                const contacts = contactsForCompanies(
                  matches.map((m) => m.value),
                  formikBag.values.contacts,
                  companyContactList?.items ?? [],
                );
                SetFormikValue(formikBag, 'contacts', contacts);
              }}
              formValueMatcher={ReferenceValueMatcher}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputMultiLookup
              name='contacts'
              label={contactDisabled ? 'Select a company' : 'Contacts'}
              disabled={contactDisabled}
              options={contactLookups}
              onMatchChange={(matches): void => {
                SetFormikValue(
                  formikBag,
                  'contacts',
                  matches.map(({ value, label }) => ({
                    id: value,
                    option: {
                      value,
                      label,
                    },
                  })),
                );
              }}
              formValueMatcher={ReferenceValueMatcher}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
        <Grid item xs={4}>
          <ButtonPill
            text='cancel'
            variant='secondary'
            onClick={(): void => {
              formikBag.resetForm();
              setIsOpen(false);
            }}
            disabled={isCreating || isUpdating}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonPill
            text='reset'
            variant='secondary'
            onClick={(): void => formikBag.resetForm()}
            disabled={!formikBag.dirty || isCreating || isUpdating}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonPill
            text='save'
            variant='primary'
            onClick={formikBag.submitForm}
            disabled={!formikBag.dirty || isCreating || isUpdating}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const ActivityDrawerPage = ({
  activityID,
  currentDate,
  setIsOpen,
  onSuccess,
  onError,
  ensureDefined = DefaultEnsureDefined,
}: ActivityDrawerProps): JSX.Element | null => {
  const isNew = activityID === null;

  const createNew = useSlabMutation('POST activity', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT activity by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingActivity,
    isError: isErrorActivity,
    data: maybeActivity,
  } = useSlabQuery(
    'GET activity by ID',
    {
      pathParams: {
        id: activityID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingActivityCompanies,
    isError: isErrorActivityCompanies,
    data: activityCompanies,
  } = useSlabQuery(
    'GET companies by activity ID',
    {
      pathParams: {
        id: activityID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingActivityContacts,
    isError: isErrorActivityContacts,
    data: activityContacts,
  } = useSlabQuery(
    'GET contacts by activity ID',
    {
      pathParams: {
        id: activityID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const loading = isLoadingActivity || isLoadingActivityCompanies || isLoadingActivityContacts;

  if (loading) {
    return <LoadingSpinner />;
  }

  const error = isErrorActivity || isErrorActivityCompanies || isErrorActivityContacts;

  if (error) {
    return <div>ERROR</div>;
  }

  const fullActivityUndefined =
    maybeActivity === undefined ||
    activityContacts === undefined ||
    activityCompanies === undefined;

  const activity = ensureDefined(
    fullActivityUndefined
      ? undefined
      : NewActivity({
          ...maybeActivity,
          companies: activityCompanies,
          contacts: activityContacts,
        }),
  );

  const formikActivity = FormikActivity({ currentDate }, activity);

  return (
    <Formik
      validationSchema={ActivitySchema}
      initialValues={formikActivity}
      onSubmit={async (values): Promise<void> => {
        // TODO #2219 Need a NewActivityFromForm wrapper for this case
        const wireActivity = new Activity(_.merge(Activity.zero(), values));
        if (formikActivity.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireActivity,
            },
            schema: ActivitySchema,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikActivity.id,
              },
              body: wireActivity,
            },
            schema: ActivitySchema,
          });
        }
      }}
    >
      <>
        <FormErrorNotification />
        <ActivityDrawerContent
          isCreating={createNew.isPending}
          isUpdating={updateExisting.isPending}
          setIsOpen={setIsOpen}
        />
      </>
    </Formik>
  );
};

export const ActivityDrawer = (props: ActivityDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <ActivityDrawerPage {...props} />
  </SlabDrawer>
);
