import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import Enums from '../../generated-types/Enums';
import { Product } from '../../generated-types/Product/Product';
import { NewProject, Project } from '../../generated-types/Project/Project';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { ArrayToIndexedRecord, DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { NewDeliveryCostOrNullFromFormik } from '../Plants/PlantFormik';
import { ProjectDrawerSections, ProjectSection } from './components/ProjectDrawerSections';
import {
  FormikProject,
  ProjectFormikType,
  ProjectSchemaFormik,
  ProjectSchemaWire,
} from './ProjectFormik';

// TODO #2219 Need a NewProjectFromForm wrapper for this case
export const formikProjectToWire = (values: ProjectFormikType): Project =>
  new Project(
    _.merge(Project.zero(), {
      ...values,
      companies: values.contractors.concat(values.otherCompanies),
      confidence:
        values.confidence !== null && values.confidence !== ''
          ? (parseFloat(values.confidence) / 100).toFixed(3)
          : null,
      customFields: values.customFields
        .filter((cf) => cf.value !== null)
        .map((cf) => ({
          definition: {
            id: cf.definition.id,
          },
          value: cf.value,
        })),
      products: values.products.map((p) => ({
        ...p,
        deliveryCosts: NewDeliveryCostOrNullFromFormik(p.deliveryCosts),
      })),
    }),
  );

type ProjectDrawerProps = Omit<SharedDrawerOverrideProps<Project>, 'resourceID' | 'onSave'> & {
  projectID: string | null;
  onSuccess: (project: Project) => void;

  /** @default 'Project Information' */
  initialSection?: ProjectSection;
  /** @default DateTime.now().year */
  initialForecastYear?: number;
};

const ProjectDrawerPage = ({
  projectID,

  isOpen,
  setIsOpen,

  initialSection = 'Project Information*',
  initialForecastYear = DateTime.now().year,

  onSuccess,
  onError,
  onClose,

  ensureDefined = DefaultEnsureDefined,
}: ProjectDrawerProps): JSX.Element | null => {
  const ctx = React.useContext(SlabContext);
  const theme = useTheme();
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  // We prefer `useRef` here because it allows for immediate mutable changes to the
  // state. If we use `useState` we see "delays" between setting values vs. rendering,
  // which causes form-values to fall out-of-sync with state-values.
  const curProductsRef = React.useRef<Record<number, Product | null>>({});

  const isNew = projectID === null;

  const createNew = useSlabMutation('POST project', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT project by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingProject,
    isError: isErrorProject,
    data: maybeProject,
  } = useSlabQuery(
    'GET project by ID',
    {
      pathParams: {
        id: projectID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
    data: projectProducts,
  } = useSlabQuery(
    'GET project products by project ID',
    {
      pathParams: {
        id: projectID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingForecasts,
    isError: isErrorForecasts,
    data: forecasts,
  } = useSlabQuery(
    'GET project forecasts by project ID',
    {
      pathParams: {
        id: projectID ?? '',
      },
    },
    { enabled: !isNew },
  );

  const {
    isLoading: isLoadingCustomFieldDefinitions,
    isError: isErrorCustomFieldDefinitions,
    data: customFieldDefinitionList,
  } = useSlabQuery('GET custom field definitions', {});

  // Once/if project products load, set them at the drawer state level.
  React.useEffect(() => {
    // When the drawer closes, clear the current state values.
    if (!isOpen) {
      curProductsRef.current = {};
      return;
    }

    // If the project is new, use the project products from context
    if (isNew) {
      const project = ensureDefined(undefined);
      const initialProductsByIndex = ArrayToIndexedRecord(
        project.products ?? [],
        (pp) => pp.product,
      );
      curProductsRef.current = initialProductsByIndex;
      return;
    }

    // An existing project is being loaded, so handle project products.
    if (isLoadingProducts || isErrorProducts || projectProducts === undefined) {
      return;
    }
    const initialProductsByIndex = ArrayToIndexedRecord(projectProducts ?? [], (pp) => pp.product);
    curProductsRef.current = initialProductsByIndex;
  }, [projectProducts, isOpen]);

  const isLoading =
    isLoadingProject || isLoadingProducts || isLoadingForecasts || isLoadingCustomFieldDefinitions;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError =
    isErrorProject || isErrorProducts || isErrorForecasts || isErrorCustomFieldDefinitions;
  const isDataUndefined = customFieldDefinitionList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const project = ensureDefined(
    maybeProject === undefined || projectProducts === undefined
      ? undefined
      : NewProject({
          ...maybeProject,
          products: projectProducts,
          forecasts,
        }),
  );

  const formikProject = FormikProject(
    project,
    customFieldDefinitionList.items,
    usesDispatchCustomer,
  );

  return (
    <Formik
      validationSchema={ProjectSchemaFormik}
      initialValues={formikProject}
      // Show pre-existing "UOM issue" product errors before interacting with that part of the form.
      validateOnMount={!isNew}
      initialTouched={{
        products: projectProducts?.map(() => ({
          product: {
            option: true,
            incompatibleMixBatchUnits: true,
            allowIncompatibleMixBatchUnits: true,
          },
        })),
      }}
      onSubmit={async (values): Promise<void> => {
        const wireProject = formikProjectToWire(values);
        if (formikProject.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireProject,
            },
            schema: ProjectSchemaWire,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikProject.id,
              },
              body: wireProject,
            },
            schema: ProjectSchemaWire,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a project
          </Typography>
          <ProjectDrawerSections
            initialSection={initialSection}
            initialForecastYear={initialForecastYear}
            curProductsRef={curProductsRef}
            omitCustomFieldSection={customFieldDefinitionList.items.length === 0}
          />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikProject });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikProject })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
          {/* If the project is not new and the plant has changed, display this warning. */}
          {(formikBag.initialValues.plant.id ?? NIL_UUID) !== NIL_UUID &&
            formikBag.initialValues.plant.id !== formikBag.values.plant.id && (
              <Box paddingY='0.5rem'>
                <Typography variant='h6' color={theme.palette.warning.main}>
                  Warning: The plant for this project has changed. Are you sure?
                </Typography>
              </Box>
            )}
          <Typography marginTop='12px'>
            To save, address any highlighted issues in red first.
          </Typography>
        </Box>
      )}
    </Formik>
  );
};

export const ProjectDrawer = (props: ProjectDrawerProps): JSX.Element => (
  <SlabDrawer
    isOpen={props.isOpen}
    paperProps={{
      sx: {
        width: '80rem',
        maxWidth: '100%',
      },
    }}
  >
    <ProjectDrawerPage {...props} />
  </SlabDrawer>
);
