import { Formik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { Product } from '../../generated-types/Product/Product';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { InitialProductDrawerStep, MainProductDrawerStep } from './components/ProductDrawerSteps';
import { FormikProduct, ProductSchema } from './ProductFormik';

export type ProductDrawerProps = Omit<
  SharedDrawerOverrideProps<Product>,
  'resourceID' | 'onSave'
> & {
  productID: string | null;
  onSuccess: (product: Product) => void;
};

const ProductDrawerPage = ({
  productID,
  setIsOpen,
  onSuccess,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: ProductDrawerProps): JSX.Element | null => {
  const isProductIDNull = productID === null;

  const [drawerStep, setDrawerStep] = useState<'initial' | 'main'>('initial');

  useEffect(() => {
    setDrawerStep(isProductIDNull ? 'initial' : 'main');
  }, [isProductIDNull]);

  const createNew = useSlabMutation('POST product', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT product by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading,
    isError,
    data: maybeProduct,
  } = useSlabQuery(
    'GET product by ID',
    {
      pathParams: {
        id: productID ?? '',
      },
    },
    { enabled: !isProductIDNull },
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>ERROR</div>;
  }

  const product = ensureDefined(maybeProduct);
  const formikProduct = FormikProduct(product);

  const isNew = formikProduct.id === NIL_UUID;

  return (
    <Formik
      validationSchema={ProductSchema}
      initialValues={formikProduct}
      onSubmit={async (values): Promise<void> => {
        // TODO #2219 Need a NewProductFromForm wrapper for this case
        const wireProduct = new Product(
          _.merge(Product.zero(), {
            ...values,
            minimumMargin: (parseFloat(values.minimumMargin) / 100).toFixed(3),
            targetMargin: (parseFloat(values.targetMargin) / 100).toFixed(3),
          }),
        );
        if (formikProduct.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireProduct,
            },
            schema: ProductSchema,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikProduct.id,
              },
              body: wireProduct,
            },
            schema: ProductSchema,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => {
        const displayedStep =
          drawerStep === 'initial' ? (
            <InitialProductDrawerStep
              setIsOpen={setIsOpen}
              onClose={onClose}
              onNext={(): void => {
                setDrawerStep('main');
              }}
            />
          ) : (
            <MainProductDrawerStep
              initialProduct={maybeProduct}
              formikProduct={formikProduct}
              isNew={isNew}
              setIsOpen={setIsOpen}
              onClose={(): void => {
                formikBag.resetForm({ values: formikProduct });
                if (isNew) {
                  setDrawerStep('initial');
                }
                onClose?.();
              }}
            />
          );
        return (
          <>
            <FormErrorNotification />
            {displayedStep}
          </>
        );
      }}
    </Formik>
  );
};

export const ProductDrawer = (props: ProductDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <ProductDrawerPage {...props} />
  </SlabDrawer>
);
