// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import {
  CustomFieldOptions,
  NewCustomFieldOptions,
} from '../CustomFieldOptions/CustomFieldOptions';
import Enums from '../Enums';

export class CustomFieldDefinition {
  readonly id: string;
  readonly name: string;
  readonly priority: number;
  readonly type: Enums.CustomFieldType;
  readonly options: CustomFieldOptions[] | null;
  readonly domainType: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.priority = data.priority === undefined ? 0 : data.priority;
    this.type = data.type === undefined ? Enums.CustomFieldType.String : data.type;
    this.options =
      // eslint-disable-next-line no-nested-ternary
      (data.options ?? null) === null
        ? null
        : (data.options ?? []).map((options: any) => NewCustomFieldOptions(options));
    this.domainType = data.domainType === undefined ? '' : data.domainType;
  }

  static zero(): CustomFieldDefinition {
    const zeroValues: ExcludeMethodsDeep<CustomFieldDefinition> = {
      id: NIL_UUID,
      name: '',
      priority: 0,
      type: Enums.CustomFieldType.String,
      options: null,
      domainType: '',
    };
    return new CustomFieldDefinition(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCustomFieldDefinition = (
  props: PartialDeep<CustomFieldDefinition, { recurseIntoArrays: true }>,
): CustomFieldDefinition => new CustomFieldDefinition(props);

export const NewCustomFieldDefinitionFromDomainObject = (
  props: PartialDeep<DomainObject<CustomFieldDefinition>, { recurseIntoArrays: true }>,
): CustomFieldDefinition => new CustomFieldDefinition(props);
