import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { useToast } from '../../components/Toast/useToast';
import Enums from '../../generated-types/Enums';
import { Mix, NewMix } from '../../generated-types/Mix/Mix';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { CurrencyString } from '../../utils/Currency';
import { convertExternalID } from '../../utils/ExternalIDHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { MaterialTable } from './components/MaterialTable';
import { MixDesignLabelOpts } from './components/MixDesignDrawerSections';
import { SimilarMixDesignTable } from './components/SimilarMixDesignTable';
import { MixDrawer } from './MixDesignDrawer';

export const DisplayMix = (
  mix: Mix,
): ExcludeMethodsDeep<Mix> & {
  plantName: string;
  materialCostString: string;
  psiString: string;
  waterCementRatioString: string;
  flyAshPercentageString: string;
  externalID: string | null;
} => ({
  ...mix,
  plantName: mix.plant.name,
  materialCostString: CurrencyString({ cur: mix.materialCost }),
  psiString: `${mix.psi.toLocaleString()} psi`,
  waterCementRatioString:
    mix.waterCementRatio === null
      ? ''
      : `${(Number(mix.waterCementRatio) * 100).toPrecision(4).replace(/\.?0+$/, '')}%`,
  flyAshPercentageString: mix.flyAshPercentage === null ? '' : `${mix.flyAshPercentage}%`,
  externalID: convertExternalID(mix.externalID),
});

export const MixDetailsPage = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const ctx = useContext(SlabContext);
  const isExternalIDEnabled = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagIntegrationImport,
  ]);

  const [initialSection, setInitialSection] = useState<MixDesignLabelOpts | undefined>();
  const [mixID, setMixID] = useState<string | null>(null);
  const [isCloning, setIsCloning] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { showToast, toast } = useToast('mix design');

  const {
    isLoading,
    isError,
    data: mix,
  } = useSlabQuery('GET mix by ID', {
    pathParams: {
      id: params?.id ?? '',
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || mix === undefined) {
    return <div>ERROR</div>;
  }

  const title = mix.name;
  const displayMix = DisplayMix(mix);

  return (
    <Page title={title}>
      {toast}
      <MixDrawer
        mixID={mixID}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        initialSection={initialSection}
        onSuccess={(m): void => {
          showToast('success');
          setIsDrawerOpen(false);
          if (isCloning) {
            setIsCloning(false);
            navigate(`/mixdesigns/${m.id}`);
          }
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
        onClose={(): void => {
          setInitialSection(undefined);
          setIsCloning(false);
        }}
        ensureDefined={(curMix): Mix => {
          if (isCloning && curMix !== undefined) {
            return NewMix({
              ...curMix,
              id: NIL_UUID,
              name: `${curMix.name} - Copy`,
              externalID: null,
            });
          }
          return curMix ?? Mix.zero();
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem' gap='1.25rem'>
        <Box display='flex' alignItems='center' gap='1.25rem'>
          <Typography variant='h1'>{title}</Typography>
          {mix.incompatibleBatchUnits && <Chip color='error' label='UOM issue' />}
        </Box>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='clone'
            variant='secondary'
            onClick={(): void => {
              setMixID(mix.id);
              setIsCloning(true);
              setInitialSection('Mix design details*');
              setIsDrawerOpen(true);
            }}
            icon='add'
          />
          <ButtonPill
            text='edit mix'
            variant='primary'
            onClick={(): void => {
              setMixID(mix.id);
              setInitialSection('Mix design details*');
              setIsDrawerOpen(true);
            }}
            icon='edit'
          />
        </Box>
      </Box>
      {mix.incompatibleBatchUnits && (
        <Box paddingBottom='1rem'>
          <Typography color={theme.palette.error.main}>
            Fix UOM issue in this mix to use it in projects or quotes
          </Typography>
        </Box>
      )}
      <Box
        display='flex'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        <DisplayField
          title='Mix Design Details'
          onEditClick={(): void => {
            setMixID(mix.id);
            setInitialSection('Mix design details*');
            setIsDrawerOpen(true);
          }}
          value={displayMix}
          displayMatrix={[
            [
              { key: 'alternateID', type: 'default', label: 'Mix ID' },
              { key: 'name', type: 'default', label: 'Name' },
              {
                key: 'plantName',
                label: 'Plant',
                type: 'uri',
                uri: `/plants/${mix.plant.id}`,
              },
              { key: 'psiString', type: 'default', label: 'PSI' },
              ...(isExternalIDEnabled
                ? [
                    {
                      key: 'materialCostString' as const,
                      type: 'default' as const,
                      label: 'Cost per unit',
                    },
                  ]
                : []),
            ],
            [
              { key: 'slump', type: 'default', label: 'Slump' },
              { key: 'aggregateSize', type: 'default', label: 'Aggregate size' },
              { key: 'waterCementRatioString', type: 'default', label: 'Water cement ratio' },
              { key: 'flyAshPercentageString', type: 'default', label: 'Fly ash percentage' },
            ],
          ]}
        />
      </Box>
      <Box paddingTop='2.5rem'>
        <MaterialTable
          mixID={mix.id}
          onEditMaterials={(): void => {
            setMixID(mix.id);
            setInitialSection('Materials');
            setIsDrawerOpen(true);
          }}
        />
      </Box>
      <Box paddingTop='2.5rem'>
        <SimilarMixDesignTable
          mix={mix}
          onEditPress={(row): void => {
            setMixID(row.id);
            setInitialSection('Mix design details*');
            setIsDrawerOpen(true);
          }}
        />
      </Box>
    </Page>
  );
};
