import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { Activities } from '../../../components/Activities/Activities';
import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Activity } from '../../../generated-types/Activity/Activity';
import { Contact } from '../../../generated-types/Contact/Contact';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { IncludeReference } from '../../../utils/DomainHelpers';

export type ContactActivitiesProps = {
  contact: Contact;
};

export const ContactActivities = ({ contact }: ContactActivitiesProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityID, setActivityID] = useState<string | null>(null);

  const {
    isLoading,
    isError,
    data: activities,
  } = useSlabQuery('GET activities by contact ID', {
    pathParams: {
      id: contact.id,
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || activities === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  const addContact = (act: Activity | undefined): Partial<Activity> => ({
    ...act,
    contacts: IncludeReference(act?.contacts, contact),
    companies: IncludeReference(act?.companies, contact.company),
  });

  return (
    <Box paddingTop='1rem'>
      <Box display='flex' justifyContent='flex-end' paddingBottom='1.5rem'>
        <ButtonPill
          text='add activity'
          variant='secondary'
          size='small'
          onClick={(): void => {
            setActivityID(null);
            setIsDrawerOpen(true);
          }}
          icon='add'
        />
      </Box>
      <Activities
        currentDate={currentDate}
        activities={activities}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        ensureDefined={addContact}
        activityID={activityID}
        setActivityID={setActivityID}
      />
    </Box>
  );
};
