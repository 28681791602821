import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Property } from 'csstype';
import React from 'react';

import Enums from '../../../generated-types/Enums';
import { NestedKeyOf } from '../../../utils/Types';
import { randomUUID } from '../../../utils/UUID';
import { Column } from '../TableDataModel';
import { SortConfig } from './SortHelpers';

type EnhancedTableProps<TRow extends { id: string }> = {
  headCells: ReadonlySet<Column<TRow>>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: NestedKeyOf<TRow>) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortConfig: SortConfig<TRow>;
  rowCount: number;
  includeSelect: boolean;
  includeEdit: boolean;
};

const columnSeparator = (color: Property.Color): JSX.Element => (
  <TableCell padding='none' sx={{ textAlign: 'right' }}>
    <Box
      sx={{
        background: color,
        height: '1rem',
        width: '2px',
        margin: '0 0.75rem',
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    />
  </TableCell>
);

/** The DataTable Head which displays column names + supports sorting */
export const EnhancedTableHead = <TRow extends { id: string }>(
  props: EnhancedTableProps<TRow>,
): JSX.Element => {
  const theme = useTheme();
  const { onSelectAllClick, sortConfig, numSelected, rowCount, onRequestSort, headCells } = props;

  const createSortHandler =
    (property: NestedKeyOf<TRow>) =>
    (event: React.MouseEvent<unknown>): void => {
      onRequestSort(event, property);
    };

  const columnHeaderContent = (headCell: Column<TRow>): JSX.Element => (
    <TableSortLabel
      active={sortConfig.name === headCell.id}
      direction={
        sortConfig.name === headCell.id
          ? sortConfig.direction
          : (headCell.initialSortOrder ?? Enums.SortDirection.Ascending)
      }
      onClick={createSortHandler(headCell.id)}
      disabled={headCell.isSortingDisabled}
    >
      {headCell.label}
      {headCell.tooltipText !== undefined && (
        <InfoOutlined color='primary' sx={{ opacity: 0.5, marginLeft: '0.25rem' }} />
      )}
      {sortConfig.name === headCell.id ? (
        <Box component='span' sx={visuallyHidden}>
          {sortConfig.direction === Enums.SortDirection.Descending
            ? 'sorted descending'
            : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );

  return (
    <TableHead
      key={randomUUID()}
      sx={{
        position: 'sticky',
        top: 0,
        background: theme.palette.SlabGray.Table,
        zIndex: theme.zIndex.tableHead,
      }}
    >
      <TableRow id={randomUUID()} sx={{ zIndex: theme.zIndex.tableRow }}>
        {props.includeSelect && headCells.size > 0 && (
          <React.Fragment key={randomUUID()}>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all',
                }}
              />
            </TableCell>
            {columnSeparator(theme.palette.SlabGray['400'])}
          </React.Fragment>
        )}
        {props.includeEdit && headCells.size > 0 && (
          <React.Fragment key={randomUUID()}>
            <TableCell padding='checkbox' />
            {columnSeparator(theme.palette.SlabGray['400'])}
          </React.Fragment>
        )}
        {[...headCells].map((headCell, index) => (
          <React.Fragment key={randomUUID()}>
            {index !== 0 && columnSeparator(theme.palette.SlabGray['400'])}
            <TableCell
              sortDirection={sortConfig.name === headCell.id ? sortConfig.direction : false}
              sx={{ padding: '0.5rem' }}
            >
              {headCell.tooltipText === undefined ? (
                columnHeaderContent(headCell)
              ) : (
                <Tooltip title={headCell.tooltipText} arrow placement='top'>
                  {columnHeaderContent(headCell)}
                </Tooltip>
              )}
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
};
