import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';
import LogRocket from 'logrocket';

import { Input } from '../../../components/Input/Input';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import { LocalLookupInput } from '../../../components/LookupInput/LocalLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import Enums from '../../../generated-types/Enums';
import { Material } from '../../../generated-types/Material/Material';
import { MaterialSummary } from '../../../generated-types/MaterialSummary/MaterialSummary';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { ListURLParams } from '../../../utils/ApiClient';
import { CostString } from '../../../utils/Currency';
import { lookups } from '../../../utils/DomainHelpers';
import { SetFormikValue } from '../../../utils/FormikHelpers';
import { List } from '../../../utils/List';
import { UnitOptions } from '../../../utils/UnitHelpers';
import { MixDesignFormikType } from '../MixDesignFormik';

const blankMixMaterial = (
  enumMaterialType: Enums.MaterialType,
): MixDesignFormikType['mixMaterials'][number] => ({
  material: {
    id: null,
    option: null,
  },
  quantity: '',
  batchUnit: null,
  materialType: enumMaterialType,
  displayOnly: {
    inProductCost: null,
    materialName: null,
  },
});

export const onMaterialChange = ({
  changedID,
  materialType,
  materialList,
}: {
  changedID: string | null;
  materialType: Enums.MaterialType;
  materialList: List<Material> | undefined;
}): {
  batchUnit: Enums.Unit | null;
  displayOnly: MixDesignFormikType['mixMaterials'][number]['displayOnly'];
} => {
  if (changedID === null) {
    return {
      batchUnit: null,
      displayOnly: blankMixMaterial(materialType).displayOnly,
    };
  }
  const mt = (materialList?.items ?? []).find((m) => m.id === changedID);

  if (mt === undefined) {
    LogRocket.error('material ID selected does not match current value set', changedID);
    return {
      batchUnit: null,
      displayOnly: blankMixMaterial(materialType).displayOnly,
    };
  }

  return {
    batchUnit: mt.rawMaterialCost.unit,
    displayOnly: {
      inProductCost: CostString({ cost: mt.inProductCost }),
      materialName: mt.name,
    },
  };
};

export const DynamicMaterialLookup = (): JSX.Element => {
  const theme = useTheme();
  const formikBag = useFormikContext<MixDesignFormikType>();

  const {
    data: materialTypeAggs,
    isLoading,
    isError,
  } = useSlabQuery(
    'GET material type aggregations',
    {
      queryParams: {
        filterBy: [
          {
            name: 'plantId',
            operation: Enums.FilterOperation.Equals,
            value: formikBag.values.plant.id ?? '',
          },
        ],
      },
    },
    { enabled: formikBag.values.plant.id !== null },
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || materialTypeAggs === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <>
      {Object.values(Enums.MaterialType).map((enumMaterialType, enumIdx) => {
        const matTypeAgg = materialTypeAggs.find(
          (mtAgg) => mtAgg.materialType === enumMaterialType,
        );
        const curFormikEnumCount = formikBag.values.mixMaterials.filter(
          (mm) => mm.materialType === enumMaterialType,
        ).length;
        const curFormikHasUnfilled = formikBag.values.mixMaterials
          .filter((mm) => mm.materialType === enumMaterialType)
          .some((mm) => mm.material.id === null);

        const isAddDisabled =
          matTypeAgg === undefined ||
          curFormikHasUnfilled ||
          matTypeAgg.count <= curFormikEnumCount;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`mix-material-section-${enumIdx}`} paddingBottom='1rem'>
            <Typography paddingBottom='0.75rem'>{enumMaterialType}</Typography>
            <FieldArray
              name='mixMaterials'
              render={(arrayHelpers): JSX.Element => (
                <>
                  {(formikBag.values.mixMaterials ?? []).map((formikMixMaterial, index) => {
                    if (formikMixMaterial.materialType !== enumMaterialType) {
                      return null;
                    }
                    return (
                      <Grid
                        // eslint-disable-next-line react/no-array-index-key
                        key={`mix-material-grid-${index}`}
                        container
                        spacing={2}
                        paddingBottom='0.5rem'
                      >
                        <Grid item xs={10}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <ApiLookupInput
                                formState={formikBag.values}
                                name={`mixMaterials.${index}.material`}
                                label='Material*'
                                route={{
                                  barrel: 'GET materials',
                                  args: (
                                    inputText,
                                  ): { queryParams: ListURLParams<MaterialSummary> } => {
                                    const args = ConstructListQueryParams(inputText);
                                    return {
                                      ...args,
                                      queryParams: {
                                        ...args.queryParams,
                                        perPage: args.queryParams.perPage + 40,
                                        filterBy: [
                                          ...(args.queryParams.filterBy ?? []),
                                          {
                                            operation: Enums.FilterOperation.Equals,
                                            name: 'plantId',
                                            value: formikBag.values.plant.id ?? '',
                                          },
                                          {
                                            operation: Enums.FilterOperation.Equals,
                                            name: 'materialType',
                                            value: enumMaterialType,
                                          },
                                        ],
                                      },
                                    };
                                  },
                                  options: {
                                    enabled: formikBag.values.plant.id !== null,
                                  },
                                  transform: (materialList): LookupInputOption[] => {
                                    // FIXME: this should use the /materials/stats endpoint to be created.
                                    const currentIDs = formikBag.values.mixMaterials.map(
                                      (mm) => mm.material.id ?? '',
                                    );
                                    const curSelectedMaterials = materialList.items.filter((m) =>
                                      currentIDs.includes(m.id),
                                    );
                                    const filteredMaterials = materialList.items
                                      .filter((m) => !currentIDs.includes(m.id))
                                      .slice(0, DEFAULT_LOOKUP_LENGTH);
                                    return lookups({
                                      items: filteredMaterials,
                                      label: (m) => m.name,
                                      // The count of selectable items excluding currently selected materials.
                                      count: materialList.count - curSelectedMaterials.length,
                                    });
                                  },
                                }}
                                // onMatch set the display-only inputs to the matching material
                                onMatchChange={(changedID, materialList): void => {
                                  const { displayOnly, batchUnit } = onMaterialChange({
                                    changedID,
                                    materialType: enumMaterialType,
                                    materialList,
                                  });

                                  SetFormikValue(
                                    formikBag,
                                    `mixMaterials.${index}.displayOnly`,
                                    displayOnly,
                                  );

                                  SetFormikValue(
                                    formikBag,
                                    `mixMaterials.${index}.batchUnit`,
                                    batchUnit,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Input
                                name={`mixMaterials.${index}.quantity`}
                                label='Quantity*'
                                type='number'
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <LocalLookupInput
                                formState={formikBag.values}
                                name={`mixMaterials.${index}.batchUnit`}
                                label='Unit*'
                                options={UnitOptions}
                                grouped
                                tip='Unit of Measurement when used in Mix Designs'
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Input
                                name={`mixMaterials.${index}.displayOnly.inProductCost`}
                                label='Material cost'
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          pt='0rem !important'
                          xs={2}
                        >
                          <Button
                            onClick={(): void => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            <Trash variant='TwoTone' color={theme.palette.SlabIndigo['700']} />
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Button
                    type='button'
                    disabled={isAddDisabled}
                    onClick={(): void => {
                      arrayHelpers.push(blankMixMaterial(enumMaterialType));
                    }}
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.SlabIndigo['700'],
                    }}
                  >
                    <Box display='flex' gap='0.5rem'>
                      <Add
                        color={
                          isAddDisabled
                            ? theme.palette.SlabGray['400']
                            : theme.palette.SlabIndigo['700']
                        }
                      />
                      Add{' '}
                      {formikBag.values.mixMaterials.filter(
                        (mm) => mm.materialType === enumMaterialType,
                      ).length === 0
                        ? 'material'
                        : 'another'}
                    </Box>
                  </Button>
                </>
              )}
            />
            {enumIdx !== Object.values(Enums.MaterialType).length - 1 ? <Divider /> : null}
          </Box>
        );
      })}
    </>
  );
};
