import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { Activities } from '../../../components/Activities/Activities';
import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Activity } from '../../../generated-types/Activity/Activity';
import { Project } from '../../../generated-types/Project/Project';
import { useSlabQuery } from '../../../hooks/useSlabQuery';

export type ProjectActivitiesProps = {
  project: Project;
};

export const ProjectActivities = ({ project }: ProjectActivitiesProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityID, setActivityID] = useState<string | null>(null);

  const {
    isLoading,
    isError,
    data: activities,
  } = useSlabQuery('GET activities by project ID', {
    pathParams: {
      id: project.id,
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || activities === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  return (
    <Box paddingTop='1rem'>
      <Box display='flex' justifyContent='flex-end' paddingBottom='1.5rem'>
        <ButtonPill
          text='add activity'
          variant='secondary'
          size='small'
          onClick={(): void => {
            setActivityID(null);
            setIsDrawerOpen(true);
          }}
          icon='add'
        />
      </Box>
      <Activities
        currentDate={currentDate}
        activities={activities}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        ensureDefined={(act: Activity | undefined): Partial<Activity> => ({
          ...act,
          project,
        })}
        activityID={activityID}
        setActivityID={setActivityID}
      />
    </Box>
  );
};
