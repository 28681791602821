import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { NestedKeyOf } from '../../utils/Types';
import { YupSchemaReferenceType } from '../../utils/YupHelpers';
import {
  ApiLookupInputBaseProps,
  GetOptFromValue,
  LookupInputAutocompleteOption,
  LookupInputInput,
  LookupInputOption,
} from './LookupInputSharedComponents';

export const ErrorOption: LookupInputOption = {
  label: 'Error loading options',
  value: 'Error',
  isDisabled: true,
};

export const LoadingOption: LookupInputOption = {
  label: 'Loading...',
  value: 'Loading',
  isDisabled: true,
};

// TODO: Remove this in favor of just the options passed in once BE filter searching works.
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: LookupInputOption) => `${option.label}${(option.sublabels ?? []).join('')}`,
});

/**
 * A Slabstack formik-friendly datalist.
 *
 * Must be used inside `<Formik><Form>{component}</Form></Formik>`.
 *
 * @example
 * <Formik {your_values_here} >
 * {(formikBag): JSX.Element => (
 *   <Form>
 *     <LocalLookupInput
 *       label='Company name'
 *       name='company.id'
 *       options={companyOpts}
 *       onMatchChange={(companyID: string | null): void => {
 *         // InputLookup has empty text (no match)
 *         if (companyID === null) {}
 *         // InputLookup has text that matches a value
 *         if (companyID !== null) {}
 *       }}
 *     />
 *   </Form>
 * </Formik>
 */
export const ApiLookupInputBase = <
  FState extends Record<string, any>,
  FPath extends NestedKeyOf<FState>,
>({
  label,
  name,
  options,
  grouped = false,
  onMatchChange,
  onInputChange,
  placeholder,
  disabled,
  tip,
  localFiltering = true,
}: ApiLookupInputBaseProps<FState, FPath>): JSX.Element => {
  const labelsByValue = new Map(options.map((o) => [o.value, o.label]));

  const { getFieldMeta } = useFormikContext();
  const formikObject = getFieldMeta(name).value;

  const isFormReference =
    Object.keys(formikObject ?? ({} as YupSchemaReferenceType)).includes('id') &&
    Object.keys(formikObject ?? ({} as YupSchemaReferenceType)).includes('option');

  // If the value has an 'id' field, consider that to be the main value used.
  const formikValue = isFormReference ? (formikObject as YupSchemaReferenceType).id : formikObject;

  const [prevFormikValue, setPrevFormikValue] = useState<unknown>(formikValue);
  const [stateOpt, setStateOpt] = useState<LookupInputOption | null>(
    GetOptFromValue(formikValue, options),
  );
  const [stateValue, setStateValue] = useState<string>(labelsByValue.get(formikValue) ?? '');

  const groupBy = !grouped ? undefined : (opt: LookupInputOption): string => opt.group ?? '';

  // Ensure that the stateValue is in sync with the field value at all times.
  useEffect(() => {
    // Options are loading or error, and are not ready to set the appropriate state option/value.
    if (options.length === 1 && (options[0] === LoadingOption || options[0] === ErrorOption)) {
      return;
    }

    // A user is mid-typing, so do not override their state.
    if (formikValue === prevFormikValue && stateValue !== '') {
      return;
    }
    // A new formikValue has appeared that must be handled.
    setPrevFormikValue(formikValue);
    setStateOpt(GetOptFromValue(formikValue, options));

    const match = labelsByValue.get(formikValue);
    if (match !== undefined) {
      setStateValue(match);
    } else {
      // When the value is cleared, trigger an onInputChange so the parent appropriately
      // handles the clearing of its `options`.
      onInputChange?.('');
      setStateValue('');
    }
  }, [formikValue, options]);

  return (
    <Field id={name} name={name}>
      {({
        field: { onBlur },
        form: { setFieldTouched, setFieldValue },
        meta,
      }: FieldAttributes<any>): JSX.Element => {
        const loadingOptions = options.length === 1 && options[0] === LoadingOption;
        const errorLoadingOptions = options.length === 1 && options[0] === ErrorOption;

        const errorMessage = isFormReference ? meta.error?.id : meta.error;
        return (
          <Autocomplete
            id={name}
            onBlur={onBlur}
            getOptionDisabled={(option): boolean => option.isDisabled === true}
            // Autocomplete has no concept of unclickable error options, so we utilize `loading` and `loadingText`
            loading={loadingOptions || errorLoadingOptions}
            loadingText={loadingOptions ? LoadingOption.label : ErrorOption.label}
            disabled={disabled}
            isOptionEqualToValue={(option, value): boolean =>
              value === undefined || option.value === value.value
            }
            inputValue={stateValue}
            onInputChange={(_, inputText): void => {
              setStateValue(inputText);
              // ApiLookupInput needs to be aware of user input text changing.
              onInputChange?.(inputText);
            }}
            value={stateOpt}
            onChange={(_, selectedOpt): void => {
              setStateOpt(selectedOpt);

              setFieldTouched(name);

              // Input has no text
              if (selectedOpt === null) {
                setFieldValue(name, isFormReference ? { id: null, option: null } : null);
                onMatchChange?.(null);
                return;
              }

              // Update the value if it is a reference, or a direct value.
              const newValue = isFormReference
                ? {
                    id: selectedOpt.value,
                    option: selectedOpt,
                  }
                : selectedOpt.value;
              setFieldValue(name, newValue);

              // Input has text that matches an LookupInputOption
              onMatchChange?.(selectedOpt.value);
            }}
            autoComplete={false}
            disablePortal
            fullWidth
            options={options}
            groupBy={groupBy}
            renderInput={(params): JSX.Element => (
              <LookupInputInput
                hasError={meta.touched === true && meta.error !== undefined}
                helperText={errorMessage}
                isLoading={loadingOptions}
                name={name}
                params={params}
                stateValue={stateValue}
                label={label}
                placeholder={placeholder}
                tip={tip}
              />
            )}
            // TODO: swap these lines once BE filtering is fully set - this is a stop-gap
            // such that until ApiLookupInputs pass queryParams, it will local filter appropriately.
            // filterOptions={(x): LookupInputOption[] => x}
            filterOptions={(opts, state): LookupInputOption[] =>
              localFiltering ? filterOptions(opts, state) : opts
            }
            renderOption={LookupInputAutocompleteOption}
          />
        );
      }}
    </Field>
  );
};
