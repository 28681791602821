// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import {
  CustomFieldDefinition,
  NewCustomFieldDefinition,
} from '../CustomFieldDefinition/CustomFieldDefinition';

export class PlantCustomField {
  readonly definition: CustomFieldDefinition;
  readonly value: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.definition = NewCustomFieldDefinition(data.definition);
    this.value = data.value === undefined ? null : data.value;
  }

  static zero(): PlantCustomField {
    const zeroValues: ExcludeMethodsDeep<PlantCustomField> = {
      definition: CustomFieldDefinition.zero(),
      value: null,
    };
    return new PlantCustomField(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewPlantCustomField = (
  props: PartialDeep<PlantCustomField, { recurseIntoArrays: true }>,
): PlantCustomField => new PlantCustomField(props);

export const NewPlantCustomFieldFromDomainObject = (
  props: PartialDeep<DomainObject<PlantCustomField>, { recurseIntoArrays: true }>,
): PlantCustomField => new PlantCustomField(props);
