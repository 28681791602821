import { Text as PdfText } from '@react-pdf/renderer';
import _ from 'lodash';
import LogRocket from 'logrocket';
import { DateTime } from 'luxon';

import { Cost } from '../../generated-types/Cost/Cost';
import { Currency } from '../../generated-types/Currency/Currency';
import Enums from '../../generated-types/Enums';
import { Styles } from '../../pdf/components/PdfTable';
import { FilterByOpts } from '../../utils/ApiClient';
import { DineroString, NullableCostString, NullableCurrencyString } from '../../utils/Currency';
import { PossiblyAsyncResult } from '../../utils/Query';
import { ExcludeFromUnion, NestedKeyOf, NestedKeyOfValue } from '../../utils/Types';
import {
  BooleanSortValue,
  CostSortValue,
  CurrencySortValue,
  DateTimeSortValue,
  DineroSortValue,
  SortConfig,
} from './components/SortHelpers';

type ColumnGetters<TRow extends { id: string }, ID extends NestedKeyOf<TRow>> =
  | {
      type: 'string';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID> & string, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & string,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & string,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & string,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'number';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID> & number, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & number,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & number,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & number,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'boolean';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID> & boolean, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & boolean,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & boolean,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & boolean,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'DateTime';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID> & DateTime, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & DateTime,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & DateTime,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & DateTime,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'Cost';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID> & Cost, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & Cost,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & Cost,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & Cost,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'Currency';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID> & Currency, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & Currency,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & Currency,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & Currency,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'Dinero';
      formatValueToString?: (
        value: NestedKeyOfValue<TRow, ID> & Dinero.Dinero,
        row: TRow,
      ) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID> & Dinero.Dinero,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID> & Dinero.Dinero,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID> & Dinero.Dinero,
        row: TRow,
      ) => string | JSX.Element | null;
    }
  | {
      type: 'custom';
      formatValueToString?: (value: NestedKeyOfValue<TRow, ID>, row: TRow) => string;
      convertValueForSort?: (
        value: NestedKeyOfValue<TRow, ID>,
        row: TRow,
      ) => string | number | null;
      formatValueForWeb?: (
        value: NestedKeyOfValue<TRow, ID>,
        row: TRow,
      ) => string | JSX.Element | null;
      formatValueForPdf?: (
        value: NestedKeyOfValue<TRow, ID>,
        row: TRow,
      ) => string | JSX.Element | null;
    };
export type ColumnConfig<TRow extends { id: string }, ID extends NestedKeyOf<TRow>> = ColumnGetters<
  TRow,
  ID
> & {
  readonly id: ID;
  readonly label: string;
  readonly tooltipText?: string;
  /** If undefined, the column will be initially displayed. */
  readonly isDisplayed?: boolean;
  /** If undefined, sorting will be enabled. */
  readonly isSortingDisabled?: boolean;
  /** If undefined, the initial sort order is `Enums.SortDirection.Ascending`. */
  readonly initialSortOrder?: Enums.SortDirection;
};

export type Column<TRow extends { id: string }> = {
  readonly id: NestedKeyOf<TRow>;
  readonly label: string;
  readonly tooltipText?: string;
  readonly initialSortOrder: Enums.SortDirection;
  readonly isSortingDisabled: boolean;
  readonly isDisplayed: boolean;
  readonly toText: (data: TRow) => string;
  readonly toSort: (data: TRow) => string | number | null;
  readonly toWeb: (data: TRow) => string | JSX.Element | null;
  readonly toPdf: (data: TRow) => string | JSX.Element | null;
};

export const StringToString = (string: string | null | undefined): string => string ?? '';
export const NumberToString = (number: number | null | undefined): string =>
  number === null || number === undefined ? '' : number.toLocaleString();
export const BooleanToString = (boolean: boolean | null | undefined): string =>
  String(boolean ?? '');
export const DateTimeToString = (dateTime: DateTime | null | undefined): string =>
  dateTime === null || dateTime === undefined ? '' : dateTime.toLocaleString(DateTime.DATE_MED);
export const CurrencyToString = (currency: Currency | null | undefined): string =>
  NullableCurrencyString({ cur: currency ?? null });
export const CostToString = (cost: Cost | null | undefined): string =>
  NullableCostString({ cost: cost ?? null });
export const DineroToString = (dinero: Dinero.Dinero | null | undefined): string =>
  dinero === null || dinero === undefined ? '' : DineroString({ dinero });

/**
 * Consider two filters to be the "same" (such that only one of them can be active at a time)
 * if they apply the same operation (to the same path, if relevant).
 */
export const HashFilter = <TRow extends { id: string }>(
  value: ExcludeFromUnion<FilterByOpts<TRow>, 'value'>,
): string => {
  if (value === undefined || value === null) {
    const errorMessage = `unsupported filter: ${value}`;
    LogRocket.error(errorMessage);
    throw new Error(errorMessage);
  }
  switch (value.operation) {
    case Enums.FilterOperation.Equals:
      return `equals|${value.name}`;
    case Enums.FilterOperation.NotEquals:
      return `notequals|${value.name}`;
    case Enums.FilterOperation.EqualsOrNull:
      return `equalsornull|${value.name}`;
    case Enums.FilterOperation.Search:
      return Enums.FilterOperation.Search;
    case Enums.FilterOperation.Lookup:
      return Enums.FilterOperation.Lookup;
    default: {
      const errorMessage = `unsupported filter type: ${(value as FilterByOpts<TRow>).operation}`;
      LogRocket.error(errorMessage);
      throw new Error(errorMessage);
    }
  }
};

export const ColumnFromConfig = <TRow extends { id: string }>(
  config: ColumnConfig<TRow, NestedKeyOf<TRow>>,
): Column<TRow> => {
  // Correctly inferring this value type is obnoxious and slow, and it doesn't improve safety outside of this specific
  // function body, so I'm just disabling type checking on this (and supplementing with test coverage).
  const getValue = (data: TRow): any => _.get(data, config.id);

  switch (config.type) {
    case 'string': {
      const formatValueToString = config.formatValueToString ?? StringToString;
      const convertValueForSort = config.convertValueForSort ?? formatValueToString;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'number': {
      const formatValueToString = config.formatValueToString ?? NumberToString;
      const convertValueForSort = config.convertValueForSort ?? ((num: number): number => num);
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'boolean': {
      const formatValueToString = config.formatValueToString ?? BooleanToString;
      const convertValueForSort = config.convertValueForSort ?? BooleanSortValue;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'Cost': {
      const formatValueToString = config.formatValueToString ?? CostToString;
      const convertValueForSort = config.convertValueForSort ?? CostSortValue;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'Currency': {
      const formatValueToString = config.formatValueToString ?? CurrencyToString;
      const convertValueForSort = config.convertValueForSort ?? CurrencySortValue;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'DateTime': {
      const formatValueToString = config.formatValueToString ?? DateTimeToString;
      const convertValueForSort = config.convertValueForSort ?? DateTimeSortValue;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'Dinero': {
      const formatValueToString = config.formatValueToString ?? DineroToString;
      const convertValueForSort = config.convertValueForSort ?? DineroSortValue;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
    case 'custom':
    default: {
      const formatValueToString = config.formatValueToString ?? String;
      const convertValueForSort = config.convertValueForSort ?? formatValueToString;
      const formatValueForWeb = config.formatValueForWeb ?? formatValueToString;
      const formatValueForPdf =
        config.formatValueForPdf ??
        ((value, row): JSX.Element => (
          <PdfText style={Styles.tableCell}>{formatValueToString(value, row)}</PdfText>
        ));
      return {
        id: config.id,
        label: config.label,
        tooltipText: config.tooltipText,
        initialSortOrder: config.initialSortOrder ?? Enums.SortDirection.Ascending,
        isSortingDisabled: config.isSortingDisabled ?? false,
        isDisplayed: config.isDisplayed ?? false,
        toText: (data: TRow): string => formatValueToString(getValue(data), data),
        toSort: (data: TRow): string | number | null => convertValueForSort(getValue(data), data),
        toWeb: (data: TRow): string | JSX.Element | null => formatValueForWeb(getValue(data), data),
        toPdf: (data: TRow): string | JSX.Element | null => formatValueForPdf(getValue(data), data),
      };
    }
  }
};

export type TableDataModel<TRow extends { id: string }, TAgg> = {
  /** Describes how the currentPage is currently sorted. */
  readonly sortConfig: SortConfig<TRow>;
  /** Described initial sort */
  readonly initialSortConfig: SortConfig<TRow>;
  /** The maximum number of rows to be rendered in the table at a time. */
  readonly rowCountPerPage: number;
  /** The total number of rows in the currently-filtered data set (across all pages). */
  readonly rowCount: PossiblyAsyncResult<number>;
  /** The 0-based index of the page that is currently selected to be rendered. */
  readonly pageIndex: number;
  /** The sequence of rows to be rendered in the table for the current pageIndex and rowCountPerPage. */
  readonly currentPage: PossiblyAsyncResult<TRow[]>;
  /** Describes all of the columns that a user can select to be rendered in this table. */
  readonly allColumns: ReadonlySet<Column<TRow>>;
  /** The columns that are currently selected for rendering. Columns will be rendered in iteration order. */
  readonly renderedColumns: ReadonlySet<Column<TRow>>;
  /** Adds or removes one of allColumns from renderedColumns. */
  setIsColumnRendered(columnID: string, isRendered: boolean): void;
  /** Returns the configured aggregations. Filters are only used for locally-processed aggregations. */
  readonly aggregations: PossiblyAsyncResult<{ [id: string]: TAgg }>;
  /**
   * Selects the page with the specified 0-based index to be rendered.
   */
  setPageIndex(pageIndex: number): Promise<void>;
  /**
   * Returns the entire list of rows in the currently-filtered data set, across all pages.
   *
   * Do not use this unless you're absolutely sure the client needs _all_ of the rows, since this
   * definitionally defeats the purpose of making paginated requests for the table's rows.
   */
  getRowsForAllPagesWithPotentiallyPoorPerformance(): Promise<TRow[]>;
  /**
   * Sets the maximum number of rows to be rendered in the table at a time, and resets the page index
   * to 0.
   *
   * The returned promise resolves when the state has been successfully updated.
   */
  setRowCountPerPage(rowCountPerPage: number): Promise<void>;
  /**
   * Sets the currentPage's sorting constraints.
   *
   * If this changes the effective sorting constraints (AKA if the new config sets a different name or
   * direction than the existing SortConfig), then setSortCOnfig will also reset the page index to 0.
   *
   * The returned promise resolves when the state has been successfully updated.
   */
  setSortConfig(config: SortConfig<TRow>): Promise<void>;
  /** Gets the currently-active filter with the same properties as hashData, if any exists. */
  getFilter(
    hashData: ExcludeFromUnion<FilterByOpts<TRow>, 'value'>,
  ): FilterByOpts<TRow> | undefined;
  /**
   * Sets the specified filters to be currently active. If calling getFilter with any of these filters
   * would return a defined value, those existing filters will be replaced.
   *
   * If this changes the effective filtering constraints (AKA if any of the added filters was not
   * already applied with the same configuration), then addFilters will also reset the page index to 0.
   *
   * The returned promise resolves when the state has been successfully updated.
   */
  addFilters(...filters: FilterByOpts<TRow>[]): Promise<void>;
  /**
   * Removes the specified filters from the currently-active list.
   *
   * If this changes the effective filtering constraints (AKA if any of the removed filters was
   * indeed previously configured), then removeFilters will also reset the page index to 0.
   *
   * The returned promise resolves when the state has been successfully updated.
   */
  removeFilters(...filters: FilterByOpts<TRow>[]): Promise<void>;
  /**
   * Removes some specified filters and adds/replaces others, all within a single rendering change.
   *
   * If this changes the effective filtering constraints, then replaceFilters will also reset the
   * page index to 0.
   *
   * The returned promise resolves when the state has been successfully updated.
   */
  replaceFilters(
    oldFilters: Iterable<FilterByOpts<TRow>>,
    newFilters: Iterable<FilterByOpts<TRow>>,
  ): Promise<void>;
  /**
   * Removes all active filters.
   *
   * If this changes the effective filtering constraints (AKA if there were any active filters
   * before this was called), then clearFilters will also reset the page index to 0.
   *
   * The returned promise resolves when the state has been successfully updated.
   */
  clearFilters(): Promise<void>;
};

export type UseReducerParams<State, Action, InitialState> = [
  React.Reducer<State, Action>,
  InitialState,
  (input: InitialState) => State,
];
