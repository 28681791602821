import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ToastHook, useToast } from '../components/Toast/useToast';
import { DefaultEnsureDefined, EnsureDefinedFunction } from '../utils/DomainHelpers';
import { QueryError } from '../utils/Query';
import { ServerErrorMessage } from './useSlabQuery';

export type SharedDrawerOverrideProps<T> = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /** The ID the drawer will use for resource API lookups. If null, will default to an empty drawer. */
  resourceID: string | null;

  /** Default behavior is to show the toast, and then set the drawer to closed. */
  onSave: (arg: T) => void;
  /** Default behavior is to show the toast with the server error message. */
  onError: (error: QueryError) => void;
  /** Default behavior is to take no action onClose.  */
  onClose?: () => void;
  /** Default behavior is to replace undefined with an empty partial via `DefaultEnsureDefined` */
  ensureDefined?: EnsureDefinedFunction<T>;
  /**
   * Default behavior is to display success toast, close the drawer, and navigate to the new resource created.
   * The navigation destination is dependent on the `baseUrl` passed into `useDrawerManager`.
   */
  onSaveAsNew?: (arg: T) => void;
};

type SharedDrawerInputProps<T> = {
  resourceTypeName: string;
  baseUrl: string;
  drawerProps: Pick<SharedDrawerOverrideProps<T>, 'resourceID'> &
    Partial<Omit<SharedDrawerOverrideProps<T>, 'isOpen' | 'setIsOpen' | 'toast'>>;
};

type ReturnSharedDrawerProps<T> = SharedDrawerOverrideProps<T> & {
  toastHook: ToastHook;
};

/**
 * Given a baseUrl, resource type name, and at least a drawer resourceID,
 * this hook will return all default shared functionality of drawer state
 * management.
 *
 * @example
 *  const { toast: contactToast, ...contactDrawerProps } = useDrawerManager({
      baseUrl: '/contacts',
      resourceTypeName: 'contact',
      drawerProps: {
        resourceID: contactID,
      },
    });
    // ...
    <ContactDrawer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...contactDrawerProps}
    />
 */
export const useDrawerManager = <T extends Record<'id', string>>({
  resourceTypeName,
  baseUrl,
  drawerProps,
}: SharedDrawerInputProps<T>): ReturnSharedDrawerProps<T> => {
  const navigate = useNavigate();
  const toastHook = useToast(resourceTypeName);
  const { showToast } = toastHook;

  const [isOpen, setIsOpen] = useState(false);

  return {
    resourceID: drawerProps.resourceID,

    // These should never be overrideable since they're basic shared functionality.
    isOpen,
    setIsOpen,

    onSave:
      drawerProps.onSave !== undefined
        ? (arg: T): void => drawerProps.onSave?.(arg)
        : (): void => {
            showToast('success');
            setIsOpen(false);
          },

    onError:
      drawerProps.onError ??
      ((error: QueryError): void => {
        showToast('error', ServerErrorMessage(error));
      }),

    onSaveAsNew:
      drawerProps.onSaveAsNew ??
      ((arg: T): void => {
        showToast('success');
        setIsOpen(false);
        navigate(`${baseUrl}/${arg.id}`);
      }),

    ensureDefined: drawerProps.ensureDefined ?? DefaultEnsureDefined,
    onClose: drawerProps.onClose,

    toastHook,
  };
};
