import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { InitialTableData, useLocalTableData } from '../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { useToast } from '../../components/Toast/useToast';
import Enums from '../../generated-types/Enums';
import { Plant } from '../../generated-types/Plant/Plant';
import { PlantListRow } from '../../generated-types/row-types';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { Intercom } from '../../Intercom';
import { SlabContext } from '../../SlabContext';
import { List } from '../../utils/List';
import { PossiblyAsyncResult } from '../../utils/Query';
import { NestedKeyOf } from '../../utils/Types';
import { PlantSection } from './components/PlantDrawerSections';
import { PlantDrawer } from './PlantDrawer';

const buildTableDataModelConfig = ({
  plantsResult,
}: {
  plantsResult: PossiblyAsyncResult<List<Plant> | undefined>;
}): InitialTableData<PlantListRow, { id: string }, List<Plant> | undefined> => {
  const transformRows = (plantList: List<Plant> = List.zero()): PlantListRow[] =>
    plantList.items.map(
      (p): PlantListRow => ({
        id: p.id,
        name: p.name,
        address: p.address.addressDisplay(),
        marketID: p.market.id,
        marketName: p.market.name,
      }),
    );

  const columns: ColumnConfig<PlantListRow, NestedKeyOf<PlantListRow>>[] = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/plants/${row.id}` }),
    },
    {
      id: 'address',
      label: 'Address',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'marketName',
      label: 'Market',
      type: 'string',
      isDisplayed: true,
    },
  ];

  return {
    rowData: plantsResult,
    transformRows,
    columns,
    initialSortPath: 'name',
  };
};

export const PlantList = (): JSX.Element => {
  const navigate = useNavigate();
  const { userInfo } = useContext(SlabContext);
  const isSlabAdmin = userInfo.hasRoles([Enums.RoleName.SlabstackAdministrator]);

  const [plantID, setPlantID] = useState<string | null>(null);
  const [, setInitialSection] = useState<PlantSection | undefined>('Plant details*');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { showToast, toast } = useToast('plant');

  const plantsResult = useSlabQuery('GET plants', {});
  const { isLoading, isError, data: plantList } = plantsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig({ plantsResult }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || plantList === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Page title='Locations'>
      {toast}
      <PlantDrawer
        plantID={plantID}
        isOpen={isDrawerOpen}
        onClose={(): void => setInitialSection(undefined)}
        setIsOpen={setIsDrawerOpen}
        onSuccess={(plant): void => {
          if (plantID === null) {
            navigate(`/plants/${plant.id}`);
          } else {
            setIsDrawerOpen(false);
            showToast('success');
          }
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Locations</Typography>
        <ButtonPill
          text='create'
          variant='primary'
          onClick={(): void => {
            if (isSlabAdmin) {
              setPlantID(null);
              setIsDrawerOpen(true);
            } else {
              // Non-Slabadmins are not allowed to create plants.
              Intercom('trackEvent', 'tried-to-create-plant');
              Intercom(
                'showNewMessage',
                'Hi\n\nI am reaching out to request your assistance with creating an additional plant for our team.',
              );
            }
          }}
          icon='add'
          tip={
            isSlabAdmin
              ? undefined
              : 'Please reach out to a Slabstack associate if you are interested in expanding your plan to include more plants.'
          }
        />
      </Box>

      <Box height='400px'>
        <DataTable
          tableName='plants'
          tableModel={tableModel}
          onEditPress={(row): void => {
            setPlantID(row.id);
            setIsDrawerOpen(true);
          }}
          includePrint={{ title: 'Plants' }}
        />
      </Box>
    </Page>
  );
};
