import { Wrapper } from '@googlemaps/react-wrapper';
import { LocationOnTwoTone } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { SyntheticEvent, useEffect, useState } from 'react';

import { Address } from '../../generated-types/Address/Address';
import { AutocompletePrediction } from '../../generated-types/AutocompletePrediction/AutocompletePrediction';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { DomainObject } from '../../utils/ApiClient';
import { QueryError } from '../../utils/Query';
import { SlabConfig } from '../../utils/SlabConfig';
import { USStates } from '../../utils/USStates';
import { Input } from '../Input/Input';
import { InputDropdown } from '../InputDropdown/InputDropdown';
import { Map, MAP_ZOOM_CLOSE, MAP_ZOOM_FAR, MapRenderer, Marker } from './Map';

/**
 * highlight is an interface that represents a fragment of text and notes if that fragment should
 * be highlighted in the autocomplete dropdown.
 */
interface highlight {
  text: string;
  highlight: boolean;
}

/** parse parses a string into parts of an autocomplete query to be highlighted
 *  and those that are not to be highlighted.
 *
 * Souce code from: https://github.com/moroshko/autosuggest-highlight/blob/master/src/parse.js
 */
const parse = (text: string, matches: [number, number][]): highlight[] => {
  const result: highlight[] = [];

  if (matches.length === 0) {
    result.push({
      text,
      highlight: false,
    });
  } else if (matches[0][0] > 0) {
    result.push({
      text: text.slice(0, matches[0][0]),
      highlight: false,
    });
  }

  matches.forEach((match, i) => {
    const startIndex = match[0];
    const endIndex = match[1];

    result.push({
      text: text.slice(startIndex, endIndex),
      highlight: true,
    });

    if (i === matches.length - 1) {
      if (endIndex < text.length) {
        result.push({
          text: text.slice(endIndex, text.length),
          highlight: false,
        });
      }
    } else if (endIndex < matches[i + 1][0]) {
      result.push({
        text: text.slice(endIndex, matches[i + 1][0]),
        highlight: false,
      });
    }
  });

  return result;
};

interface ResponseErrors {
  autocomplete: QueryError | null;
  convert: QueryError | null;
  details: QueryError | null;
}

const QueryErrors = (errors: ResponseErrors): JSX.Element => {
  const [message, setMessage] = useState<string | null>(null);
  useEffect(() => {
    const autocomplete = errors.autocomplete?.response?.data?.message ?? null;
    const convert = errors.convert?.response?.data?.message ?? null;
    const details = errors.details?.response?.data?.message ?? null;
    if (autocomplete === null && convert === null && details === null) {
      setMessage(null);
    } else {
      setMessage(
        (autocomplete !== null ? `${autocomplete}. ` : '') +
          (convert !== null ? `${convert}. ` : '') +
          (details !== null ? `${details}. ` : ''),
      );
    }
  }, [errors]);
  return <div>{message !== null && <FormHelperText error>{message}</FormHelperText>}</div>;
};

/**
 * AddressForm is the Formik form component that contains all of the user input
 * components for an address.
 */
const AddressForm = ({
  parentName,
  autocompleteOptions,
  onAutocompleteEvent,
  onOptionSelected,
  errors,
}: {
  parentName: string;
  autocompleteOptions: AutocompletePrediction[] | undefined;
  onAutocompleteEvent: (e: SyntheticEvent<Element, Event>, query: string) => Promise<void>;
  onOptionSelected: (
    e: SyntheticEvent<Element, Event>,
    option: AutocompletePrediction | string | null,
  ) => void;
  errors: ResponseErrors;
}): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field id={`${parentName}.line1`} name={`${parentName}.line1`}>
            {({ field: { value }, meta }: FieldProps<string | null>): JSX.Element => {
              const hasError = meta.touched === true && meta.error !== undefined;
              // The value of the autocomplete result. Updated on blur, when the user hits enter, when an option is
              // selected, etc. Defaults to null but will be updated with user input or the loaded address's line1
              const [autocompleteValue, setAutocompleteValue] = useState<
                null | string | AutocompletePrediction
              >(null);

              // When formik value has changed, set the autocomplete value to the formik value
              useEffect(() => {
                if (value !== undefined) {
                  setAutocompleteValue(value);
                }
              }, [value]);

              return (
                <Autocomplete
                  id={`${parentName}.line1.autocomplete`}
                  // this allows for free form input to be considered an option
                  getOptionLabel={(option): string =>
                    typeof option === 'string' ? option : (option.description ?? '')
                  }
                  isOptionEqualToValue={(option, val): boolean => {
                    if (typeof option === 'string' || typeof val === 'string') {
                      return option === val;
                    }
                    return option?.place_id === val?.place_id;
                  }}
                  options={autocompleteOptions ?? []}
                  value={autocompleteValue}
                  freeSolo
                  autoComplete
                  filterSelectedOptions
                  filterOptions={(x): AutocompletePrediction[] => x}
                  onInputChange={onAutocompleteEvent}
                  onChange={onOptionSelected}
                  renderInput={(params): JSX.Element => (
                    <>
                      <TextField
                        {...params}
                        id={`${parentName}.line1.text`}
                        value={value}
                        name={`${parentName}.line1.text`}
                        label='Line 1'
                        error={hasError}
                        helperText={hasError ? meta.error : ' '}
                        placeholder='Line 1'
                        fullWidth
                      />
                      <QueryErrors {...errors} />
                    </>
                  )}
                  renderOption={(props, option): JSX.Element => {
                    const matches =
                      typeof option === 'string'
                        ? option
                        : option.structured_formatting?.main_text_matched_substrings;
                    const parts = parse(
                      typeof option === 'string'
                        ? option
                        : (option.structured_formatting?.main_text ?? ''),
                      typeof matches === 'string'
                        ? []
                        : (matches?.map((match) => [match.offset, match.offset + match.length]) ??
                            []),
                    );

                    return (
                      <li {...props}>
                        <Grid container spacing={1} alignItems='center'>
                          <Grid item>
                            <Box
                              component={LocationOnTwoTone}
                              sx={{ color: theme.palette.grey[600] }}
                            />
                          </Grid>
                          <Grid item xs>
                            {parts.map(
                              (part, index): JSX.Element => (
                                <span
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ),
                            )}
                            <Typography variant='body2' color={theme.palette.grey[600]}>
                              {typeof option === 'string'
                                ? option
                                : option.structured_formatting?.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
              );
            }}
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={12}>
          <Input name={`${parentName}.line2`} label='Line 2' />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input name={`${parentName}.city`} label='City' />
        </Grid>
        <Grid item xs={6}>
          <InputDropdown name={`${parentName}.state`} label='State*' options={USStates} />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input name={`${parentName}.postalCode`} label='Postal Code' />
        </Grid>
        <Grid item xs={6}>
          <Input name={`${parentName}.country`} label='Country' />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap='nowrap'>
        <Grid item xs={6}>
          <Input name={`${parentName}.latitude`} label='Latitude' />
        </Grid>
        <Grid item xs={6}>
          <Input name={`${parentName}.longitude`} label='Longitude' />
        </Grid>
      </Grid>
    </>
  );
};

/**
 * AddressMap is the component that renders the address input fields and a Google Maps
 * map that interacts with the input fields. Users can click on the map, use the
 * autocomplete provided in the line1 input box, or enter any other value to set the
 * value of an address.
 */
export const AddressMap = ({
  name,
  hideMap = false,
  onAddressUpdated = (): void => {},
}: {
  name: string;
  /** @default false */
  hideMap?: boolean;
  /** This will be run after setFormikAddress(address). Formik is not guaranteed to be side-effect free */
  onAddressUpdated?: (address: Address) => void;
}): JSX.Element => {
  const formik = useFormikContext();
  // Set the formik value of the address
  const setFormikAddress = formik.getFieldHelpers<DomainObject<Address>>(name).setValue;
  // Set the formik value of the line1 field of the address
  const setFormikLine1 = formik.getFieldHelpers<string | null>(`${name}.line1`).setValue;
  // The initial value of the address, only used for initializing
  const formikAddress = formik.getFieldMeta<DomainObject<Address>>(name).value;
  // There are consts ZOOM_CLOSE for when a location is selected and ZOOM_FAR for when a location is not selected
  const [zoom, setZoom] = useState(formikAddress.latitude !== null ? MAP_ZOOM_CLOSE : MAP_ZOOM_FAR);
  // The string text input used to search for autocomplete results
  const [autocompleteQuery, setAutocompleteQuery] = useState<string | null>(null);
  // The list of options returned by the server after searching for results with autocompleteQuery
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompletePrediction[]>();
  // The placeID of the autocomplete result selected, used to query the server for location details
  const [placeID, setPlaceID] = useState<string | null>(null);
  // The location that the user clicks on the map
  const [click, setClick] = useState<google.maps.LatLngLiteral | undefined>(undefined);
  // Any errors that come back from a query
  const [queryErrors, setQueryErrors] = useState<ResponseErrors>({
    autocomplete: null,
    convert: null,
    details: null,
  });
  // The center of the map
  const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>(
    formikAddress !== undefined &&
      formikAddress.latitude !== null &&
      formikAddress.latitude !== undefined &&
      formikAddress.longitude !== null &&
      formikAddress.longitude !== undefined
      ? {
          lat: parseFloat(formikAddress.latitude),
          lng: parseFloat(formikAddress.longitude),
        }
      : undefined,
  );
  // The new center of the map that triggers a details query after a click
  const [newLocation, setNewLocation] = useState<google.maps.LatLngLiteral | undefined>(center);

  // Get location details based on autocompletion input
  const {
    data: placeData,
    isLoading: placeLoading,
    isError: placeIsError,
    error: placeError,
    isSuccess: placeIsSuccess,
  } = useSlabQuery(
    'GET maps place',
    {
      queryParams: {
        id: placeID ?? '',
      },
    },
    {
      enabled: placeID !== null && placeID !== '',
      retry: (_, err): boolean => {
        // Don't retry on a client error or when no results are found
        if (err.response?.status === 404 || err.response?.status === 400) {
          return false;
        }
        // Do retry on an API/server error
        return true;
      },
    },
  );
  // Update error state when place request finishes.
  useEffect(() => {
    if (placeIsSuccess) {
      setQueryErrors({
        ...queryErrors,
        details: null,
      });
    }
  }, [placeIsSuccess]);
  useEffect(() => {
    if (placeIsError) {
      setQueryErrors({
        ...queryErrors,
        details: placeError,
      });
    }
  }, [placeIsError, placeError]);

  // Get location details based on mouse clicks.
  const {
    data: convertedLatLong,
    isLoading: convertedLatLongIsLoading,
    isError: convertedLatLongIsError,
    error: convertedLatLongError,
    isSuccess: convertedLatLongIsSuccess,
  } = useSlabQuery(
    'GET maps conversion',
    {
      queryParams: {
        lat: click?.lat.toFixed(7) ?? '',
        long: click?.lng.toFixed(7) ?? '',
        address: '',
      },
    },
    {
      enabled: click !== undefined,
      retry: (_, err): boolean => {
        // Don't retry on a client error or when no results are found
        if (err.response?.status === 404 || err.response?.status === 400) {
          return false;
        }
        // Do retry on an API/server error
        return true;
      },
    },
  );
  // Update error state when conversion request finishes.
  useEffect(() => {
    if (convertedLatLongIsSuccess) {
      setQueryErrors({
        ...queryErrors,
        convert: null,
      });
    }
  }, [convertedLatLongIsSuccess]);
  useEffect(() => {
    if (convertedLatLongIsError) {
      setQueryErrors({
        ...queryErrors,
        convert: convertedLatLongError,
      });
    }
  }, [convertedLatLongIsError, convertedLatLongError]);

  // Get the autocomplete results based on the query in the line1 input field.
  const {
    data: autocompleteResult,
    isLoading: autocompleteIsLoading,
    isError: autocompleteIsError,
    error: autocompleteError,
    isSuccess: autocompleteIsSuccess,
  } = useSlabQuery(
    'GET maps autocomplete',
    {
      queryParams: {
        query: autocompleteQuery ?? '',
      },
    },
    {
      enabled: autocompleteQuery !== null && autocompleteQuery !== '',
      retry: (_, err): boolean => {
        // Don't retry on a client error or when no results are found
        if (err.response?.status === 404 || err.response?.status === 400) {
          return false;
        }
        // Do retry on an API/server error
        return true;
      },
    },
  );
  // Update error state when autocomplete request finishes.
  useEffect(() => {
    if (autocompleteIsSuccess) {
      setQueryErrors({
        ...queryErrors,
        autocomplete: null,
      });
    }
  }, [autocompleteIsSuccess]);
  useEffect(() => {
    if (autocompleteIsError) {
      // The options list will just be empty when no autocomplete results are returned
      if (autocompleteError.response?.status === 404) {
        setQueryErrors({
          ...queryErrors,
          autocomplete: autocompleteError,
        });
      } else {
        // Clear out previous errors if there is an empty response
        setQueryErrors({
          ...queryErrors,
          autocomplete: null,
        });
      }
    }
  }, [autocompleteIsError, autocompleteError]);

  // When an autocomplete option is selected, set placeID to the option's place_id
  const onOptionSelected = (
    _: SyntheticEvent<Element, Event>,
    option: string | AutocompletePrediction | null,
  ): void => {
    if (typeof option !== 'string' && option !== null) {
      setPlaceID(option.place_id ?? null);
    }
  };

  // Move the marker when the user clicks on the map
  useEffect(() => {
    if (click !== undefined) {
      setNewLocation(click);
    }
  }, [click]);

  // After place details are loaded, update the formikAddress and adjust the map.
  useEffect(() => {
    if (!placeLoading && !placeIsError && placeData !== undefined) {
      // Set the formik address to the returned address value
      setFormikAddress(placeData.address);
      onAddressUpdated(placeData.address);
      if (placeData.address.latitude !== null && placeData.address.longitude !== null) {
        // Set the marker location to the returned value
        setCenter({
          lat: parseFloat(placeData.address.latitude),
          lng: parseFloat(placeData.address.longitude),
        });
        setNewLocation({
          lat: parseFloat(placeData.address.latitude),
          lng: parseFloat(placeData.address.longitude),
        });
        if (zoom !== MAP_ZOOM_CLOSE) {
          // Zoom in, if not currently zoomed in
          setZoom(MAP_ZOOM_CLOSE);
        }
      }
    }
  }, [placeData]);

  // This is called when the input text in the autocomplete's input box is changed
  const onAutocompleteEvent = async (
    e: SyntheticEvent<Element, Event>,
    query: string,
  ): Promise<void> => {
    // Keep address.line1 in sync with the input box. This is needed in the case that neither clicking on the map or
    // the autocomplete is used to input an address
    setFormikLine1(query);
    // Set the variable used to query the server for autocomplete results
    setAutocompleteQuery(query);
    if ((query === null || query === '') && e !== null) {
      // Clear stale options when the input is cleared
      setAutocompleteOptions([]);
      setQueryErrors({
        autocomplete: null,
        convert: null,
        details: null,
      });
      setFormikAddress(Address.zero());
      onAddressUpdated(Address.zero());
    }
  };

  // Set the autocomplete options when the query autocomplete refreshes.
  useEffect(() => {
    if (!autocompleteIsError && !autocompleteIsLoading && autocompleteResult !== undefined) {
      setAutocompleteOptions(autocompleteResult);
    }
  }, [autocompleteResult]);

  // Update the map's view window and the formik address after the location query
  // returns after a mouse click.
  useEffect(() => {
    if (!convertedLatLongIsLoading && !convertedLatLongIsError && convertedLatLong !== undefined) {
      // Set the formik address to the location of the click
      setFormikAddress(convertedLatLong.address);
      onAddressUpdated(convertedLatLong.address);
      if (zoom !== MAP_ZOOM_CLOSE) {
        // Zoom in, if not currently zoomed in
        setZoom(MAP_ZOOM_CLOSE);
      }
      // Set the marker's location
      setCenter(convertedLatLong.location);
    }
  }, [convertedLatLong]);

  // Called when the user clicks on the map
  const onMapClick = (e: google.maps.MapMouseEvent): void => {
    if (e.latLng !== null) {
      // Set the center of the map and the map marker to the click location
      setClick({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    }
  };

  if (SlabConfig.googleMaps.id === undefined) {
    return <Typography>Cannot load Google Maps</Typography>;
  }

  return (
    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
      <Wrapper
        apiKey={SlabConfig.googleMaps.id}
        render={MapRenderer}
        libraries={['places', 'marker']}
      >
        <AddressForm
          parentName={name}
          autocompleteOptions={autocompleteOptions}
          onAutocompleteEvent={onAutocompleteEvent}
          onOptionSelected={onOptionSelected}
          errors={queryErrors}
        />
        {!hideMap && (
          <Map center={center} zoom={zoom} onClick={onMapClick} gestureHandling='cooperative'>
            <Marker
              key={`${newLocation?.lat ?? 0},${newLocation?.lng ?? 0}`}
              location={newLocation}
            />
          </Map>
        )}
      </Wrapper>
    </Box>
  );
};
