import { Box, MenuItem, Modal, Select, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import Enums from '../../../generated-types/Enums';
import {
  NewPdfTemplateFromDomainObject,
  PdfTemplate,
} from '../../../generated-types/PdfTemplate/PdfTemplate';
import { Quote } from '../../../generated-types/Quote/Quote';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { buildPdf } from '../../../pdf/buildPdf';
import { Base64, binaryStringToBase64 } from '../../../types/Base64';
import { randomUUID } from '../../../utils/UUID';

// Used if the user has 0 pdf templates to pick from.
const defaultQuoteTemplate = NewPdfTemplateFromDomainObject({
  name: 'Default Quote',
  template: 'DefaultQuote',
  parts: [],
  type: 'code',
});

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '80vw',
  maxWidth: '90vw',
  height: '90vh',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  overflowY: 'scroll',
};

type QuotePdfModalProps = {
  quote: Quote;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  requiresQuoteApprovals: boolean;
  onPdfChanged?: (pdfBase64: Base64) => void;
  /** @default true */
  showCancelButton?: boolean;
};

export const QuotePdfModalContent = ({
  quote,
  setIsOpen,
  onPdfChanged,
  requiresQuoteApprovals,
  showCancelButton = true,
}: Omit<QuotePdfModalProps, 'isOpen'>): JSX.Element => {
  const [pdfTemplate, setPdfTemplate] = useState<PdfTemplate | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const [pdfIsLoadingSpinner, setPdfIsLoadingSpinner] = useState(false);

  const {
    data: pdfTemplateList,
    isLoading: isTemplateLoading,
    isError: isTemplateError,
  } = useSlabQuery('GET pdf templates', {});
  const {
    data: generatedPdf,
    isLoading: isPdfLoading,
    isError: isPdfError,
  } = useSlabQuery(
    'GET quote pdf by quote ID and template ID',
    { pathParams: { quoteID: quote.id, templateID: pdfTemplate?.id ?? '' } },
    {
      enabled: pdfTemplate?.type === Enums.PdfTemplateType.PdfGenerator,
      // Don't automatically reload PDFs, because that's unexpected for users
      // and also unnecessarily burns PDF generator quota.
      staleTime: Infinity,
    },
  );

  // Once list is loaded, set the pdfTemplate to the first value in the list.
  useEffect(() => {
    if (pdfTemplateList !== undefined) {
      const firstTemplateTemplate = _.first(pdfTemplateList.items) ?? defaultQuoteTemplate;
      setPdfTemplate(firstTemplateTemplate);
    }
  }, [pdfTemplateList]);

  // Whenever the pdfTemplate changes, this will rebuild the PDF.
  useEffect(() => {
    if (pdfTemplateList === undefined) {
      return;
    }

    setPdfIsLoadingSpinner(true);
    if (pdfTemplate === null) {
      // nothing to do yet
      return;
    }

    if (isPdfError) {
      const errorDoc = '<div>Error generating quote PDF</div>';
      setPdfUrl(`data:text/html,${encodeURIComponent(errorDoc)}`);
      onPdfChanged?.(binaryStringToBase64(errorDoc));
      setPdfIsLoadingSpinner(false);
      return;
    }

    if (pdfTemplate.type === Enums.PdfTemplateType.Code) {
      // react-pdf
      buildPdf(quote, pdfTemplate).then((base64) => {
        setPdfUrl(
          `data:application/pdf;base64,${base64}${
            requiresQuoteApprovals && !quote.status.isApprovedToSend && !quote.status.isSent
              ? '#toolbar=0'
              : ''
          }`,
        );
        onPdfChanged?.(base64);
        setPdfIsLoadingSpinner(false);
      });
      return;
    }

    if (pdfTemplate.type === Enums.PdfTemplateType.PdfGenerator) {
      // pdf-generator-api
      if (!isPdfLoading && generatedPdf !== undefined) {
        const base64 = generatedPdf.pdf;
        setPdfUrl(
          `data:application/pdf;base64,${base64}${
            requiresQuoteApprovals && !quote.status.isApprovedToSend && !quote.status.isSent
              ? '#toolbar=0'
              : ''
          }`,
        );
        onPdfChanged?.(base64);
        setPdfIsLoadingSpinner(false);
      }
    }
  }, [pdfTemplate, generatedPdf, isPdfError]);

  if (isTemplateLoading || isPdfLoading) {
    return <LoadingSpinner />;
  }

  if (isTemplateError || pdfTemplateList === undefined) {
    return <div>Error generating quote PDF</div>;
  }

  const renderPdf = (): JSX.Element => {
    if (pdfIsLoadingSpinner) {
      return <LoadingSpinner />;
    }
    if (isPdfError) {
      return <div>ERROR</div>;
    }
    return <iframe title='Quote' width='100%' height='100%' src={pdfUrl} />;
  };

  return (
    <Box display='flex' flexDirection='column' gap='2rem' flexGrow={1}>
      {pdfTemplateList.count > 0 ? (
        <Select
          value={pdfTemplate?.id ?? ''}
          onChange={(e): void => {
            const value = pdfTemplateList.items.find((item) => item.id === e.target.value);
            setPdfTemplate(value ?? null);
          }}
          fullWidth
          // Close outlined box
          sx={{
            legend: {
              display: 'none',
            },
          }}
        >
          {pdfTemplateList.items.map(
            (template): JSX.Element => (
              <MenuItem key={randomUUID()} value={template.id}>
                {template.name}
              </MenuItem>
            ),
          )}
        </Select>
      ) : (
        <Typography variant='h5'>
          Feel free to contact a Slabstack administrator to request custom built quote PDFs.
        </Typography>
      )}
      <Box flexGrow={1} minHeight='90vh' maxHeight='120rem'>
        {renderPdf()}
      </Box>
      <Box
        display='flex'
        gap='1rem'
        sx={{ '& button, & a': { width: '100%', whiteSpace: 'nowrap' } }}
      >
        <a
          href={
            requiresQuoteApprovals && !quote.status.isApprovedToSend && !quote.status.isSent
              ? undefined
              : pdfUrl
          }
          target='_blank'
          rel='noopener noreferrer'
          download={quote.fileName()}
          style={{ textDecoration: 'none' }}
          aria-label='download'
        >
          <ButtonPill
            disabled={
              requiresQuoteApprovals && !quote.status.isApprovedToSend && !quote.status.isSent
            }
            text='download'
            variant='primary'
            onClick={(): void => {}}
          />
        </a>

        {showCancelButton && (
          <ButtonPill
            text='cancel'
            variant='secondary'
            onClick={(): void => {
              setIsOpen(false);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export const QuotePdfModal = ({
  quote,
  isOpen,
  setIsOpen,
  requiresQuoteApprovals,
  showCancelButton = true,
}: Omit<QuotePdfModalProps, 'onBlobChanged'>): JSX.Element | null => (
  <Modal open={isOpen}>
    <Box sx={style}>
      <Typography variant='h3'>Preview</Typography>
      <QuotePdfModalContent
        quote={quote}
        setIsOpen={setIsOpen}
        requiresQuoteApprovals={requiresQuoteApprovals}
        showCancelButton={showCancelButton}
      />
    </Box>
  </Modal>
);
