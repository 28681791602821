import { Box, Typography } from '@mui/material';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Currency } from '../../../generated-types/Currency/Currency';
import { Units } from '../../../generated-types/generated-units';
import { NewQuoteConfig, QuoteConfig } from '../../../generated-types/QuoteConfig/QuoteConfig';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

type QuoteConfigProductListRow = {
  order: number;
  id: string;
  alternateID: string;
  name: string;
  category: string;
  price: Currency | null;
  measurementUnit: string;
  suggestedPrice: Currency | null;
};

const buildTableDataModelConfig = ({
  quoteConfigResult,
}: {
  quoteConfigResult: PossiblyAsyncResult<QuoteConfig | undefined>;
}): InitialTableData<QuoteConfigProductListRow, { id: string }, QuoteConfig | undefined> => {
  const transformRows = (
    quoteConfig: QuoteConfig = NewQuoteConfig({}),
  ): QuoteConfigProductListRow[] =>
    quoteConfig.products.map(
      (p, idx): QuoteConfigProductListRow => ({
        // 'order' uses index assuming that the products are already sorted by order.
        order: idx + 1,

        id: p.product.id,
        alternateID: p.product.alternateID,
        name: p.product.name,
        category: p.product.category,
        price: p.product.listPrice,
        measurementUnit: Units[p.product.measurementUnit].label,
        suggestedPrice: p.product.calculateSuggestedPrice(),
      }),
    );

  const columns: ColumnConfig<QuoteConfigProductListRow, NestedKeyOf<QuoteConfigProductListRow>>[] =
    [
      {
        id: 'order',
        label: 'Order',
        type: 'number',
        isDisplayed: false,
      },
      {
        id: 'alternateID',
        label: 'Product ID',
        type: 'string',
        isDisplayed: true,
      },
      {
        id: 'name',
        label: 'Name',
        type: 'string',
        isDisplayed: true,
        formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/products/${row.id}` }),
      },
      {
        id: 'category',
        label: 'Category',
        type: 'string',
        isDisplayed: true,
      },
      {
        id: 'price',
        label: 'Price',
        type: 'Currency',
        isDisplayed: true,
      },
      {
        id: 'measurementUnit',
        label: 'Unit',
        type: 'string',
        isDisplayed: true,
      },
      {
        id: 'suggestedPrice',
        label: 'Suggested price',
        type: 'Currency',
        isDisplayed: false,
      },
    ];

  return {
    rowData: quoteConfigResult,
    transformRows,
    columns,
    initialSortPath: 'order',
  };
};

type QuoteConfigProductTableProps = {
  quoteConfigID: string;
  onEditProducts: () => void;
};

export const QuoteConfigProductTable = ({
  quoteConfigID,
  onEditProducts,
}: QuoteConfigProductTableProps): JSX.Element => {
  const quoteConfigResult = useSlabQuery('GET quote config by ID', {
    pathParams: {
      id: quoteConfigID,
    },
  });
  const { isLoading, isError, data: quoteConfig } = quoteConfigResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig({ quoteConfigResult }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || quoteConfig === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' paddingBottom='1.5rem' alignItems='center'>
        <Typography variant='h4'>Default additional products</Typography>
        <ButtonPill
          text='edit products'
          variant='primary'
          size='small'
          onClick={onEditProducts}
          icon='edit'
        />
      </Box>
      <DataTable tableName='quote-configs-additional-products' tableModel={tableModel} />
    </Box>
  );
};
