import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { YearSelector } from '../../../components/YearSelector/YearSelector';
import Enums from '../../../generated-types/Enums';
import { Forecast } from '../../../generated-types/Forecast/Forecast';
import { NewProject, Project } from '../../../generated-types/Project/Project';
import { MonthlyForecastCells } from '../../../generated-types/row-types';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { MergeAsyncResults, PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';
import { filteredMappedForecasts, possibleYears } from '../../Forecasts/ForecastHelpers';

type ProjectForecastListRow = MonthlyForecastCells & {
  id: string;
  year: number;
  total: number;
};

// Abusing aggregations to calculate the set of year options for the filter menu.
type YearAggregation = {
  id: 'yearOpts';
  years: ReturnType<typeof possibleYears>;
};

const constructForecastRows = (project: Project): ProjectForecastListRow[] => {
  const yearOpts = possibleYears({
    yearsWithForecasts: new Set(project.forecasts.map((f) => f.intervalStart.year)),
  });
  return yearOpts.map(({ year }) => {
    const fmForecasts = filteredMappedForecasts(project.forecasts, year);
    const total = Object.values(fmForecasts).reduce((acc, f) => acc + Number(f ?? 0), 0);

    return {
      id: `${project.id}@${year}`,
      year,
      total,
      ...fmForecasts,
    };
  });
};

type ProjectAndForecasts = {
  project: Project | undefined;
  forecasts: Forecast[] | undefined;
};

const buildTableDataModelConfig = ({
  projectResult,
  forecastsResult,
  selectedYear,
}: {
  projectResult: PossiblyAsyncResult<Project | undefined>;
  forecastsResult: PossiblyAsyncResult<Forecast[] | undefined>;
  selectedYear: number;
}): InitialTableData<ProjectForecastListRow, YearAggregation, ProjectAndForecasts> => {
  const transformRows = ({
    project = NewProject({}),
    forecasts = [],
  }: ProjectAndForecasts): ProjectForecastListRow[] => {
    const projectWithForecasts = project.fillForecasts(forecasts);
    return constructForecastRows(projectWithForecasts);
  };

  const columns: ColumnConfig<ProjectForecastListRow, NestedKeyOf<ProjectForecastListRow>>[] = [
    {
      id: 'total',
      type: 'number',
      isDisplayed: true,
      label: 'Total',
    },
    {
      id: '1',
      label: DateTime.fromObject({ year: selectedYear, month: 1 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '2',
      label: DateTime.fromObject({ year: selectedYear, month: 2 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '3',
      label: DateTime.fromObject({ year: selectedYear, month: 3 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '4',
      label: DateTime.fromObject({ year: selectedYear, month: 4 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '5',
      label: DateTime.fromObject({ year: selectedYear, month: 5 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '6',
      label: DateTime.fromObject({ year: selectedYear, month: 6 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '7',
      label: DateTime.fromObject({ year: selectedYear, month: 7 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '8',
      label: DateTime.fromObject({ year: selectedYear, month: 8 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '9',
      label: DateTime.fromObject({ year: selectedYear, month: 9 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '10',
      label: DateTime.fromObject({ year: selectedYear, month: 10 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '11',
      label: DateTime.fromObject({ year: selectedYear, month: 11 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '12',
      label: DateTime.fromObject({ year: selectedYear, month: 12 }).monthShort ?? '',
      type: 'number',
      isDisplayed: true,
    },
  ];

  const aggregateRows = ({
    rawData,
  }: {
    rawData: ProjectAndForecasts | undefined;
  }): { [id: string]: YearAggregation } => {
    const project = rawData?.project ?? NewProject({});
    const forecasts = rawData?.forecasts ?? [];
    const projectForecasts = project.fillForecasts(forecasts ?? []).forecasts;
    const years = new Set(
      projectForecasts.filter((f) => !['', '0'].includes(f.value)).map((f) => f.intervalStart.year),
    );
    return {
      yearOpts: {
        id: 'yearOpts',
        years: possibleYears({ yearsWithForecasts: years }),
      },
    };
  };

  return {
    rowData: MergeAsyncResults({ project: projectResult, forecasts: forecastsResult }),
    columns,
    transformRows,
    initialSortPath: 'total',
    aggregateRows,
    filters: [{ operation: Enums.FilterOperation.Equals, name: 'year', value: selectedYear }],
  };
};

type ForecastTableProps = {
  projectID: string;

  // NOTE: We pass the year from useState of the parent ([set]selectedYear) so the child useState
  // will not default to "now" when the ForecastTable component is re-rendered.
  selectedYear: number;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;

  onEditForecasts: () => void;
};

export const ForecastTable = ({
  projectID,
  selectedYear,
  setSelectedYear,
  onEditForecasts,
}: ForecastTableProps): JSX.Element => {
  const projectResult = useSlabQuery('GET project by ID', {
    pathParams: {
      id: projectID,
    },
  });
  const { isLoading: isLoadingProject, isError: isErrorProject, data: project } = projectResult;

  const forecastsResult = useSlabQuery('GET project forecasts by project ID', {
    pathParams: {
      id: projectID,
    },
  });
  const {
    isLoading: isLoadingForecastList,
    isError: isErrorForecastList,
    data: forecastList,
  } = forecastsResult;

  const tableModel = useLocalTableData(
    buildTableDataModelConfig({
      projectResult,
      forecastsResult,
      selectedYear,
    }),
  );

  const isLoading = isLoadingProject || isLoadingForecastList;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorProject || isErrorForecastList;
  const isDataUndefined = project === undefined || forecastList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  tableModel.addFilters({
    operation: Enums.FilterOperation.Equals,
    name: 'year',
    value: selectedYear,
  });

  const rows = tableModel.currentPage.data;
  const totalForecast = rows.reduce((sum, row) => sum + Number(row.total), 0);

  const yearOpts = tableModel.aggregations.data.yearOpts.years;

  return (
    <Box p='1rem'>
      <Box pb='1rem'>
        <Typography variant='h4'>Forecast: {totalForecast.toLocaleString()}</Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' paddingBottom='1rem'>
        <YearSelector
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          yearOpts={yearOpts}
        />
        <ButtonPill
          text='edit forecasts'
          size='small'
          onClick={onEditForecasts}
          variant='primary'
          icon='edit'
        />
      </Box>

      <DataTable tableName='project-forecasts' tableModel={tableModel} />
    </Box>
  );
};
